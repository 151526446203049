
export const googleAuth={
  clientId: "876644585762-nq0j86q4dvt6lp2el36926ouea8ri0p3.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/calendar"
} 

export const sentryDSN=process.env.REACT_APP_SENTRY_DSN

const config:any = {
  chargebee: {
    local: {
      site: 'blabberapp-test',
      publishableKey: 'test_bgFvx5v8OpAbcdxyjjaxMmfhn0mbN93Ck'
    },
    development: {
      site: 'blabberapp-test',
      publishableKey: 'test_bgFvx5v8OpAbcdxyjjaxMmfhn0mbN93Ck'
    },
    testing: {
      site: 'blabberapp-test',
      publishableKey: 'test_bgFvx5v8OpAbcdxyjjaxMmfhn0mbN93Ck'
    },
    staging: {
      site: 'blabberapp-test',
      publishableKey: 'test_bgFvx5v8OpAbcdxyjjaxMmfhn0mbN93Ck'
    },
    prod: {
      site: 'blabberapp',
      publishableKey: 'live_o5EA82sJQkUpRXjqcuVmAX0hSKZiFiJhK'
    }
  }
}

export const ChargebeeConfig = config.chargebee[process.env.REACT_APP_ENV || 'local']
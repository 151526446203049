// import store from 'store2';
// import superagent from 'superagent';

import config from '../../clientConfig';
const apiUrl = config.apiUrl;


const modulePath = "transcription/";
const FLUSH = `${modulePath}FLUSH`;
const FLUSH_TRANSCRIPTION = `${modulePath}FLUSH_TRANSCRIPTION`;
const SET_IS_TRANSCRIPTION_POPUP = `${modulePath}SET_IS_TRANSCRIPTION_POPUP`;
const SET_TRANSCRIPTION = `${modulePath}SET_TRANSCRIPTION`;
const SET_IS_TRANSCRIPTION = `${modulePath}SET_IS_TRANSCRIPTION`;

const initialState = {
  isTranscriptionPopUp: false,
  transcriptions: {},
  isTranscription: false
};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {

    case SET_IS_TRANSCRIPTION_POPUP:
      return { ...state, isTranscriptionPopUp: action.payload };

    case SET_TRANSCRIPTION:
      return { ...state, transcriptions: action.payload };

    case SET_IS_TRANSCRIPTION:
      return { ...state, isTranscription: action.payload };

    case FLUSH_TRANSCRIPTION:
      return { ...state, transcriptions: null };

    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}

export const setIsTranscriptionPopUp = (isTranscriptionPopUp) => async (dispatch, getState, api) => {
  // alert(SET_IS_TRANSCRIPTION_POPUP);
  dispatch({
    type: SET_IS_TRANSCRIPTION_POPUP,
    payload: isTranscriptionPopUp
  })
}

export const setIsTranscription = (isTranscription) => async (dispatch, getState, api) => {
  // alert(SET_IS_TRANSCRIPTION_POPUP);
  dispatch({
    type: SET_IS_TRANSCRIPTION,
    payload: isTranscription
  })
}

export const setTranscription = (newTranscriptions) => async (dispatch, getState, api) => {
  // const oldTranscriptions = getState().transcription.transcriptions;
  // console.log('oldTranscriptions', oldTranscriptions);
  // console.log('newTranscriptions', newTranscriptions);
  await dispatch({
    type: SET_TRANSCRIPTION,
    // payload: [ ...oldTranscriptions, ...newTranscriptions]
    payload: newTranscriptions
  });

  const transHTMLElement = document.getElementById('end');
  if (transHTMLElement) {
    // console.log('hello');
    transHTMLElement.scrollIntoView({ behavior: "smooth" });
  }
}
export const flushTranscription = () => async (dispatch, getState, api) => {
  // alert(SET_IS_TRANSCRIPTION_POPUP);
  dispatch({
    type: FLUSH_TRANSCRIPTION
  })
}

export const createTranscriptionFile = () => async (dispatch, getState, api) => {
  try {
    const meetingTitle = getState()?.meeting?.meeting?.meetingTitle;
    if (meetingTitle) {
      const res = await api.post(`${apiUrl}meeting/start-meeting-transcription`, {
        data: {
          meetingTitle
        }
      }); 
      if (res.data) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
}

export const getTranscriptionFiles = () => async (dispatch, getState, api) => {
  try {
    const userId = getState()?.auth?.user?.id;
    const res = await api.get(`${apiUrl}file/get-transcript?user_id=${userId}`);
    // console.log('getTranscriptionFiles res =>', res);
  } catch (error) {
  }
}

import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import auth from './modules/auth';
import meeting from './modules/meeting';
import InviteSettings from './modules/InviteSettings';

import users from './modules/users';
import notification from './modules/notification';
import files from './modules/files';
import transcription from './modules/transcription';
import subscription from './modules/subscription';

export default combineReducers({
  form: FormReducer,
  toastr: toastrReducer,
  auth,
  meeting,
  InviteSettings,
  users,
  notification,
  files,
  transcription,
  subscription
});

import superagent from 'superagent';
// import Axios from 'axios';
import config from '../../clientConfig';


const modulePath = "file/";
const apiUrl = config.apiUrl + modulePath;

const FILE = `${modulePath}FILE`;
const FILE_SUCCESS = `${modulePath}FILE_SUCCESS`;
const FILE_FAIL = `${modulePath}FILE_FAIL`;
const SET_TRANSCRIPTION_FILES = `${modulePath}SET_TRANSCRIPTION_FILES`;

const initialState = {
  fileBusy: false,
  fileError: false,
  file: null,
  transcriptionFiles: []
};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case FILE:
      return { ...state, fileBusy: true, fileError: null };
    case FILE_SUCCESS:
      return { ...state, fileBusy: false, file: action.file };
    case FILE_FAIL:
      return { ...state, fileBusy: false, fileError: action.error };
    case SET_TRANSCRIPTION_FILES:
      return { ...state, transcriptionFiles: action.payload };
    default:
      return state;
  }
}

export const uploadFile = (file, userId: string) => async (dispatch, getState, api) => {
  dispatch({ type: FILE });
  try {
    const response = await superagent.post(apiUrl + `upload-file/` + userId)
      .attach('file', file);
    dispatch({ type: FILE_SUCCESS, file: response.body.data });
    return response.body;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: FILE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
    dispatch({ type: FILE_FAIL, error });
    console.error("fileupload", error);
  }
};

export const getFiles = (fileId) => async (dispatch, getState, api) => {
  dispatch({ type: FILE });
  try {
    const response = await superagent.get(apiUrl + `get-files/` + fileId);
    dispatch({ type: FILE_SUCCESS, file: response.body.data });
    return response.body;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: FILE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
    dispatch({ type: FILE_FAIL, error });
    console.error("getFiles", error);
  }
};

export const getFile = (fileId) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.get(apiUrl + `fetch-file/` + fileId)
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }
};

export const imageFiles = (parentId) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.get(apiUrl + `get-images/` + parentId)
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const updateFileName = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.patch(apiUrl + `update-file-name`)
      .send(data)
      .set({ 'Content-Type': 'application/json' });
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const deleteFile = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.delete(apiUrl + `delete-file/` + data.file_id)
    return response.body.data;
  } catch (error) {
    console.error("deleteFile", error);
  }
}

export const removeRecording = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `remove-recording`)
    .send(data)
    .set({ 'Content-Type': 'application/json' });
    return response.body.data;
  } catch (error) {
    console.error("deleteRecording", error);
  }
}

export const searchFiles = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `search-files`)
      .send(data)
      .set({ 'Content-Type': 'application/json' });
    return response.body;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const fetchDownloadFiles = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.get(apiUrl + `download-file/` + data + '.pdf')
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const downloadRecording = (url, name) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `download-recording`).send({ url });
    return response.body.data;
    // const res = await Axios({
    //   url: apiUrl + `download-recording`, method: 'POST', responseType: 'blob', data: {
    //     url
    //   }
    // })
    //   .then((response) => {
    //     console.log(response)
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', name);
    //     document.body.appendChild(link);
    //     link.click();
    //   });
    // return res;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const getRecording = (user_id) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.get(apiUrl + `get-recordings?user_id=${user_id}`)
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const getTranscript = () => async (dispatch, getState, api) => {
  try {
    const userId = getState()?.auth?.user?.id;
    const response = await api.get(`${apiUrl}get-transcript?user_id=${userId}`);
    const transcriptionFiles = response?.data?.length > 0 ? response.data : [];
    await dispatch({
      type: SET_TRANSCRIPTION_FILES,
      payload: transcriptionFiles
    });
    return transcriptionFiles;
  } catch (error) {
    console.error("getUser", error);
  }
}

export const getSignedUrl = (data) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `get-signed-url`)
      .send(data)
      .set({ 'Content-Type': 'application/json' });
    // console.log("response", response.body)
    return response.body.data;
  } catch (error) {
    console.error("getUser", error);
  }


}

export const updateRecordingName = (params:any) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `edit-recording-name`).send(params);
    return response.body.data;
  } catch (error) {
    console.error("updateFileName", error);
  }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import store from "store2";
import { toastr } from "react-redux-toastr";
// import { Svg } from "../../components/";

class home extends Component<propTypes, stateType> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidCatch(error) {
		toastr.error("error", "something went wrong");
		console.error(error);
		this.props.history.push("/error/404");
	}

	componentDidMount = async () => {
		const authToken = store("authToken");
		const authUser = store("authUser");
		if (authToken && authUser) {
			this.props.history.push("/user/dashboard");
		}
		const jquery = document.getElementById('jquery');
		if (!jquery) {
			const script = document.createElement('script');
			script.src = 'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=620776dfd0f5fd69a4a77912';
			script.id = 'jquery';
			document.body.appendChild(script);
		}
		const webflowjs = document.getElementById('webflowjs');
		if (!webflowjs) {
			const script = document.createElement('script');
			script.src = 'js/webflow.js';
			script.id = 'webflowjs';
			document.body.appendChild(script);
		}
	};

	componentWillUnmount(): void {
		const jquery = document.getElementById('jquery');
		const webflowjs = document.getElementById('webflowjs');
		if (jquery && webflowjs) {
			jquery.remove();
			webflowjs.remove();
		}
	}

	render() {
		return (
			<div className="strike-website-homepage">
				<meta charSet="utf-8" />
				<title>Strike App for Google</title>
				<meta content="width=device-width, initial-scale=1" name="viewport" />
				<meta content="Webflow" name="generator" />
				<link href="css/normalize.css" rel="stylesheet" type="text/css" />
				<link href="css/webflow.css" rel="stylesheet" type="text/css" />
				<link
					href="css/strike-landing-page-feb-2022.webflow.css"
					rel="stylesheet"
					type="text/css"
				/>
				<style dangerouslySetInnerHTML={{
					__html: `
				@import url(\\css\\normalize.css);
				@import url(\\css\\webflow.css);
				@import url(\\css\\strike-landing-page-feb-2022.webflow.css);

				.w-slider-dot {
					background: teal;
				}
				.w-slider-dot.w-active {
					background: aqua;
				}
				` }} />
				<span className="af-view">
					<div>
						<div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-3 w-nav">
							<div className="af-class-container-4 w-container">
								<nav role="navigation" className="af-class-nav-menu-3 w-nav-menu">
									<a href="/" aria-current="page" className="af-class-nav-link w-nav-link w--current">Overview</a>
									<a href="about-us" className="af-class-nav-link w-nav-link black-link">About Us</a>
									<a href="/login" className="af-class-nav-link w-nav-link">Log In</a>
									<a href="/join-waitlist" className="af-class-button-small af-class-_2 w-nav-link">Join Waitlist</a>
								</nav>
								<div className="af-class-menu-button w-nav-button">
									<div className="w-icon-nav-menu" />
								</div>
								<a href="/" aria-current="page" className="af-class-strike w-inline-block w--current">
									<img src="images/Strike-Logo-Type_1.svg" loading="lazy" width={98} alt="img" className="af-class-image-14" />
								</a>
							</div>
						</div>
						<div className="af-class-line" />
						<div className="af-class-section-1 af-class-wf-section">
							<div className="af-class-hero-heading-container w-container">
								<h1 className="af-class-heading-h1">Reimagine Virtual Meetings and Online Collaboration</h1>
								<p className="af-class-subtitle-1">Find a common time, schedule, attach agendas, take automated AI or your own private or shareable notes, never forget who you met or why, prep for upcoming meetings, all the while unleashing team-wide collaboration and sharing</p>
								<a href="/join-waitlist" className="af-class-button-large w-button">Join Waitlist</a>
							</div>
						</div>
						<div className="af-class-section-1b af-class-wf-section">
							<div data-delay={4000} data-animation="slide" className="af-class-slider w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={8} data-duration={500} data-infinite="true">
								<div className="af-class-mask w-slider-mask">
									<div className="af-class-slide-5 w-slide">
										<div className="af-class-slide-styler"><img src="images/Section-1-Asset-1.1.svg" loading="lazy" alt="img" className="af-class-image-8" />
											<div className="af-class-slide-content"><img src="images/1.1.svg" loading="lazy" alt="img" className="af-class-icon" />
												<div className="af-class-value-prop">
													<h3 className="af-class-heading-h3 dark-grey-text">Smart Scheduling</h3>
												</div>
												<p className="af-class-subtitle-2">Let Strike AI make meetings happen and never send back and forth emails again to find a time to connect. Find times for one-on-one or one-to-many meetings with a click.</p>
											</div>
										</div>
									</div>
									<div className="af-class-slide-4 w-slide">
										<div className="af-class-slide-styler"><img src="images/Section-1-Asset-1.2.svg" loading="lazy" alt="img" className="af-class-image-9" />
											<div className="af-class-slide-content"><img src="images/1.2.svg" loading="lazy" alt="img" className="af-class-icon af-class-_1-2" />
												<div className="af-class-value-prop">
													<h3 className="af-class-heading-h3 dark-grey-text">Most productive meetings. Ever.</h3>
												</div>
												<p className="af-class-subtitle-2">Never forget what was discussed, who said what, key takeaways, to-do’s and next steps. Let Strike AI transcribe and summarize your meetings or take private or shareable notes and keep everyone in sync.</p>
											</div>
										</div>
									</div>
									<div className="af-class-slide-3 w-slide">
										<div className="af-class-slide-styler"><img src="images/Section-1-Asset-1.3.svg" loading="lazy" alt="img" className="af-class-image-10" />
											<div className="af-class-slide-content"><img src="images/1.3.svg" loading="lazy" alt="img" className="af-class-icon af-class-_1-3" />
												<div className="af-class-value-prop">
													<h3 className="af-class-heading-h3 dark-grey-text">All your meeting data in one place. Forever.</h3>
												</div>
												<p className="af-class-subtitle-2">Access, search, share, and oragnize all past meeting data. Keep track of who you met, why you met them, next steps, key takeaways, and all context for past and future<br />meetings</p>
											</div>
										</div>
									</div>
									<div className="af-class-slide-6 w-slide">
										<div className="af-class-slide-styler"><img src="images/Section-1-Asset-1.4.svg" loading="lazy" alt="img" className="af-class-image-11" />
											<div className="af-class-slide-content"><img src="images/1.4.svg" loading="lazy" alt="img" className="af-class-icon af-class-_1-4" />
												<div className="af-class-value-prop">
													<h3 className="af-class-heading-h3 dark-grey-text">Never forget who you met.<br />Or Why.</h3>
												</div>
												<p className="af-class-subtitle-2">Strike AI remembers everyone you meet so you don’t have to. See all past interactions by person and prep for your next meeting on the fly. &nbsp;Exchange ‘Digicards’ to add them to your network and do even more.</p>
											</div>
										</div>
									</div>
									<div className="af-class-slide-7 w-slide">
										<div className="af-class-slide-styler"><img src="images/Point-5.svg" loading="lazy" width={50} alt="img" className="af-class-image-12" />
											<div className="af-class-slide-content"><img src="images/1.5.svg" loading="lazy" alt="img" className="af-class-icon af-class-_1-5" />
												<h1 className="af-class-tag">Coming Soon</h1>
												<div className="af-class-value-prop">
													<h3 className="af-class-heading-h3 dark-grey-text">Keep your entire team in sync. And collaborate.</h3>
												</div>
												<p className="af-class-subtitle-2">Create Channels and invite your team members to collaborate and stay in lock step with everything that is going on.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="w-slider-arrow-left" />
								<div className="w-slider-arrow-right" />
								<div className="af-class-slide-nav w-slider-nav w-round" />
							</div>
						</div>
						<div className="af-class-section-2 af-class-wf-section">
							<div className="af-class-hero-heading-container w-container">
								<h1 className="af-class-heading-h1 af-class-section-2">One platform to do it all!</h1>
								<p className="af-class-subtitle-1 af-class-section-2">Transform the way you meet online. Reclaim your productivity. <br />Collaborate with everyone on your team.</p>
							</div>
							<div className="af-class-smart-scheduling">
								<h2 className="af-class-heading---h2 dark-grey-text">Smart Scheduling Made Easy</h2>
								<div className="af-class-value-prop-image"><img src="images/Section-2-asset.svg" loading="lazy" alt="img" className="af-class-scheduling-image" />
									<div className="af-class-columns">
										<div className="af-class-synchronized-scheduling">
											<h3 className="af-class-heading-h3 dark-grey-text">Synchronized Scheduling</h3>
											<p className="af-class-subtitle-3">With Strike SyncAI, finding a time that works for everyone is as easy as a click. Simply select the days and times that work for you and share it with all participants and then let our AI do the rest. No more playing email roulette. No more fuss.</p>
										</div>
										<div className="af-class-calendar-link">
											<h3 className="af-class-heading-h3 dark-grey-text">Calendar Link</h3>
											<p className="af-class-subtitle-3">Schedule meetings directly from the app or customize your availability and share your calendar in a link making scheduling a breeze. No more back-and-forth emails. No more waiting to confirm meetings.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-3 af-class-wf-section">
							<div className="af-class-productive-meeting">
								<h2 className="af-class-heading---h2 dark-grey-text">Make Every Meeting a Productive Meeting!</h2><img src="images/Section-3-asset.svg" loading="lazy" alt="img" className="af-class-image-3" />
								<div className="af-class-pointers">
									<div className="af-class-rows">
										<div className="af-class-productive-meeting-points">
											<h4 className="af-class-heading---h4 dark-grey-text">Schedule Follow-ups Before You Hang Up</h4>
											<p className="af-class-subtitle-4">Schedule a follow-up right from your current virtual meeting. Simply select the days and times that work for everyone and schedule with one click. No more taking it offline. Easy peasy!</p>
										</div>
										<div className="af-class-productive-meeting-points">
											<h3 className="af-class-heading---h4 dark-grey-text">Pre-Meeting Agendas and In-Meeting Notes</h3>
											<p className="af-class-subtitle-4">Set Agendas for all meetings to keep everyone on the same page. Capture and arrange your thoughts and ideas while in your meeting by taking Notes that are meant only for your eyes or to be shared with everyone.</p>
										</div>
									</div>
									<div className="af-class-rows">
										<div className="af-class-productive-meeting-points">
											<h3 className="af-class-heading---h4 dark-grey-text">Immersive Presentation Modes</h3>
											<p className="af-class-subtitle-4">Wow on video and present like a champ. Don’t hide behind your slides and make your presentations pop with custom effects. No more boring, monotonous meetings. No more lackluster presentations.</p>
										</div>
										<div className="af-class-productive-meeting-points">
											<h3 className="af-class-heading---h4 dark-grey-text">Transcribe in real-time with Strike AI</h3>
											<p className="af-class-subtitle-4">Focus on the discussion and let Strike AI do the rest and transcribe your conversations in real-time and organize it in one place. No more forgetting that stellar idea or important take-away.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-4 af-class-wf-section">
							<h2 className="af-class-heading---h2 dark-grey-text">Tame the Data Chaos from Online Meetings</h2>
							<div className="af-class-value-prop-image"><img src="images/Section-4-asset.svg" loading="lazy" alt="img" className="af-class-meeting-image" />
								<div className="af-class-columns">
									<div className="af-class-meeting-history">
										<h3 className="af-class-heading---h4 dark-grey-text">Meeting History</h3>
										<p className="af-class-subtitle-4">Centralized repository for all meeting data. Access, search, share, and oragnize past agenda items, notes, transcripts, recordings, action items, to-dos, and takeaways.</p>
									</div>
									<div className="af-class-meeting-channels">
										<div className="af-class-div-block-2">
											<h3 className="af-class-heading---h4 af-class-_2 dark-grey-text">Meeting Channels</h3>
											<h3 className="af-class-tag af-class-_2">Coming Soon</h3>
										</div>
										<p className="af-class-subtitle-4">Create Channels, invite teammates, and tag meetings to keep your entire team always &nbsp;in sync with whatwas discussed, key takeaways, next steps, and to-do’s.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-5 af-class-wf-section">
							<h2 className="af-class-heading---h2 dark-grey-text">Don’t Just Meet. Connect.</h2>
							<div className="af-class-value-prop-image"><img src="images/Section-5-asset.svg" loading="lazy" alt="img" className="af-class-connect-image" />
								<div className="af-class-columns">
									<div className="af-class-meeting-history">
										<h3 className="af-class-heading---h4 dark-grey-text">Exchange Digi-Card</h3>
										<p className="af-class-subtitle-4">Exchange business cards on Strike and save them in your Contacts. Add personal notes, view Contact profile and other information. No more forgetting who you met and when.</p>
									</div>
									<div className="af-class-meeting-channels">
										<div className="af-class-div-block-2">
											<h3 className="af-class-heading---h4 af-class-_2 dark-grey-text">Interaction History</h3>
											<h3 className="af-class-tag af-class-_2">Coming Soon</h3>
										</div>
										<p className="af-class-subtitle-4">View all past interactions by Contact. Keep track of who you met, why you met them, next steps, key takeaways, and all context for past and future meetings.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-6 af-class-wf-section">
							<h2 className="af-class-heading---h2 dark-grey-text">One Workspace for All Collaboration</h2>
							<div className="af-class-value-prop-image"><img src="images/Section-6-asset.svg" loading="lazy" alt="img" className="af-class-workspace-image" />
								<div className="af-class-columns">
									<div className="af-class-meeting-history">
										<h3 className="af-class-heading---h4 dark-grey-text">Presentation Drive</h3>
										<p className="af-class-subtitle-4">Upload key presentations and files to upcoming meetings and make them instantly available to all participants. Present files natively with one click. No more hunting for meeting documents in your email. All your content in one place.</p>
									</div>
									<div className="af-class-meeting-channels">
										<div className="af-class-div-block-2">
											<h3 className="af-class-heading---h4 dark-grey-text af-class-_2">Shared Notes</h3>
											<h3 className="af-class-tag af-class-_2">Coming Soon</h3>
										</div>
										<p className="af-class-subtitle-4">Take collaborative notes for everyone on the call and share them instantly via Channels (coming soon) or Meeting History. No more emailing takeaways, next steps, and action items to your team members, even if they missed the meeting.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-7 af-class-wf-section">
							<h1 className="af-class-heading-h1 af-class-dark">Who can use?</h1>
							<div className="af-class-persona-container w-container">
								<div className="af-class-card-row">
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">Sales / Marketing</h1>
										<p className="af-class-body">Find a time, send an invite, and set up a meeting with a single click – no more back and forth to set up that demo. Take automated, real-time notes of all the details in your meeting and never forget a requirement or what your customer has spoken about. Upload your pitch to Strike and present with panache, no matter where you are or what device. Strike is designed to help Sales teams make an impression and win (and save time)!</p>
									</div>
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">Product Teams</h1>
										<p className="af-class-body">With a click, find a time and meet with your teams to align on product vision, strategy and roadmap and spend more time creating the next great thing while never missing an important idea or task. Even if you miss the meeting or join late.</p>
									</div>
								</div>
								<div className="af-class-card-row">
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">Engineering Teams</h1>
										<p className="af-class-body">Your engineering teams are busy enough building the next big thing. Keep them in sync no matter where they are. With a click, find a time and meet with your teammates to align on your design, development, testing and roadmap strategy. Have Strike take all the notes and action items, while your teams focus on the really important stuff.</p>
									</div>
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">Customer Success</h1>
										<p className="af-class-body">Gather customer feedback and share the most important takeaways with your team - verbatim. Strike transcribes your calls in real-time and saves all your notes in one place. Now you can spend all your time focused on the things that matter during your meetings and not distracted by jotting down notes. Strike has end-to-end scheduling and calendaring functionality built in. Just integrate into your calendar and let Strike to the rest.</p>
									</div>
								</div>
								<div className="af-class-card-row">
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">Education</h1>
										<p className="af-class-body">Keep all your students and participants in sync with the only app you’ll ever need. Plan, host, present, record and transcribe your meetings so that everyone pays attention while in class and can review key takeaways and concepts afterwards, even if they missed the meeting.</p>
									</div>
									<div className="af-class-card">
										<h1 className="af-class-heading-h3 dark-grey-text">All Remote Teams</h1>
										<p className="af-class-body">The only app you’ll need to stay in plan, host, present, record and transcribe meetings. Find a time, send an invite, and set up a meeting with a single click – no more back and forth emails to set up that meeting. Take automated, real-time notes of all the details in your meeting and share with your teammates – even if they miss the meeting.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="af-class-section-8 af-class-wf-section">
							<div className="af-class-testimonial af-class-slider-2 af-class-tm"><img src="images/Quotation-mark.svg" loading="lazy" alt="img" className="af-class-image-5" />
								<div className="af-class-testimonial-text">
									<p className="af-class-subtitle-3 af-class-testimonial">I love using Strike for my meetings. It’s a game changer as far as my productivity goes: I can easily go back to the transcript from a meeting I had 2 months ago and exactly locate what my clients said. This goes a long way in assuring customer success because I no longer have to rely on my handwritten notes. I also love the fact that I can keep my own private notes right next to the transcript to follow up with my biz dev guys when contracts are up for renewal. Strike gives me a much desired user experience I’ve craved since the pandemic made F2F conversations with my customers impossible.</p>
									<p className="af-class-subtitle-2 af-class-testimonial">Gaurav Murghai, CEO of Softobiz</p>
								</div>
							</div>
							<div data-delay={4000} data-animation="slide" className="af-class-slider-2 w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
								<div className="af-class-mask-2 w-slider-mask">
									<div className="af-class-slide-2 w-slide">
										<div className="af-class-testimonial af-class-slider-2"><img src="images/Quotation-mark.svg" loading="lazy" alt="img" className="af-class-image-5" />
											<div className="af-class-testimonial-text">
												<p className="af-class-subtitle-3 af-class-testimonial">I love using Strike for my meetings. It’s a game changer as far as my productivity goes: I can easily go back to the transcript from a meeting I had 2 months ago and exactly locate what my clients said. This goes a long way in assuring customer success because I no longer have to rely on my handwritten notes. I also love the fact that I can keep my own private notes right next to the transcript to follow up with my biz dev guys when contracts are up for renewal. Strike gives me a much desired user experience I’ve craved since the pandemic made F2F conversations with my customers impossible.</p>
												<p className="af-class-subtitle-2 af-class-testimonial">Gaurav Murghai, CEO of Softobiz</p>
											</div>
										</div>
									</div>
									<div className="af-class-slide w-slide">
										<div className="af-class-testimonial af-class-slider-2"><img src="images/Quotation-mark.svg" loading="lazy" alt="img" className="af-class-image-5" />
											<div className="af-class-testimonial-text">
												<p className="af-class-subtitle-3 af-class-testimonial">I really like the way you can take notes in real time and have those notes tied to the transcription. Really great transcription with notes attached to them is a game-changer for me. Really great transcription with notes attached to them is a game-changer for me.</p>
												<p className="af-class-subtitle-2 af-class-testimonial">Gaurav Murghai, CEO of Softobiz</p>
											</div>
										</div>
									</div>
								</div>
								<div className="af-class-left-arrow w-slider-arrow-left"><img src="images/Left-arrow.svg" loading="lazy" alt="img" className="af-class-image-4" /></div>
								<div className="af-class-right-arrow w-slider-arrow-right"><img src="images/Right-arrow.svg" loading="lazy" alt="img" className="af-class-image-6" /></div>
								<div className="af-class-slide-nav-2 w-slider-nav w-round" />
							</div>
						</div>
						<div className="af-class-section-9 af-class-wf-section">
							<h1 className="af-class-heading-h1 af-class-dark">Make Your Meetings Awesome.<br />Try Strike.</h1>
							<a href="/join-waitlist" target="_blank" className="af-class-button-large w-button">Join Waitlist</a>
						</div>
						<footer id="footer" className="af-class-footer af-class-wf-section">
							<div className="af-class-container-2 w-container">
								<div className="af-class-footer-flex-container">
									<a href="/" className="af-class-footer-logo-link"><img src="images/Strike-Logo-Footer.svg" alt="img" className="af-class-footer-image" /></a>
									<div>
										<h2 className="af-class-footer-heading">Product</h2>
										<div className="af-class-line" />
										<ul className="af-class-list w-list-unstyled">
											<li>
												<a href="/" aria-current="page" className="af-class-footer-link w--current">Overview</a>
											</li>
											<li>
												<a href="/join-waitlist" target="_blank" className="af-class-footer-link">Join Waitlist</a>
											</li>
										</ul>
									</div>
									<div>
										<h2 className="af-class-footer-heading">Company</h2>
										<div className="af-class-line" />
										<ul className="af-class-list-2 w-list-unstyled">
											<li>
												<a href="/about-us" className="af-class-footer-link">About Us</a>
											</li>
										</ul>
									</div>
									<div>
										<h2 className="af-class-footer-heading">Help and Support</h2>
										<div className="af-class-line" />
										<ul className="af-class-list-3 w-list-unstyled">
											<li>
												<a href="mailto: support@strikeapp.co?subject=Hello%20Strike%20Team!%20" className="af-class-footer-link">Contact Us</a>
											</li>
										</ul>
									</div>
									<a href="https://www.linkedin.com/company/strike-collaboration" target="_blank" rel="noreferrer" className="w-inline-block"><img src="images/linkedin.svg" loading="lazy" alt="img" /></a>
								</div>
								<div className="af-class-div-block-3">
									<div className="af-class-text-block">Copyright © 2022 All rights reserved Blabberapp, LLC.</div>
									<a href="/privacypolicy" className="af-class-footer-link af-class-_2">Terms of Use</a>
									<div className="af-class-div-block-4" />
									<a href="/privacypolicy" className="af-class-footer-link af-class-_2">Privacy Policy</a>
								</div>
							</div>
						</footer>
						{/* [if lte IE 9]><![endif] */}
					</div>
				</span>

			</div>
		);
	}
}
export default connect((state: any) => ({
	error: state.auth.loginError,
	loading: state.auth.loginBusy,
	user: state.auth.user,
}))(home);

type propTypes = {
	history: any;
	dispatch: any;
	user: any;
	error: any;
	terms: number;
	loading: boolean;
};
type stateType = {};

// import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { reducer as formReducer } from 'redux-form';
// import Immutable from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import moduleReducers from './moduleReducers';
import ApiClient from './ApiClient';
import clientMiddleware from './clientMiddleware';
// import routerReducer from './reactRouterReducer';
import { save } from "redux-localstorage-simple"

const api = new ApiClient();

export default function configureStore(history: any, preLoadedState: any) {
  const w: any = window;
  const middlewares = [
    thunkMiddleware.withExtraArgument(api),
    clientMiddleware(api),
    routerMiddleware(history),
    save({namespace: 'strike_ls'})
  ];
  const loadState = () => {
    // return undefined;
    try {
      const serializedState = localStorage.getItem('strike_ls');
      if(serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return undefined;
    }
  } 

  if (process.env.NODE_ENV !== 'production') {
    if (!w.devToolsExtension) {
      // const { logger } = require('redux-logger');
      // middlewares.push(logger);
    }
  }

  const enhancer = compose(
    applyMiddleware(...middlewares),
    // other store enhancers if any,
    w.__REDUX_DEVTOOLS_EXTENSION__ ? w.__REDUX_DEVTOOLS_EXTENSION__({
      name: 'Nozani', actionsBlacklist: ['REDUX_STORAGE_SAVE']
    }) : (noop: any) => noop
  );

  const initialState = loadState() //preLoadedState || {}; // Immutable.fromJS(preLoadedState || {});
  return createStore(moduleReducers, initialState, enhancer);
}

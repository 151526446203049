import React, { Component } from "react";
import { connect } from "react-redux";
import store from "store2";
import { toastr } from "react-redux-toastr";
// import { Svg } from "../../components/";

class about extends Component<propTypes, stateType> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidCatch(error) {
		toastr.error("error", "something went wrong");
		console.error(error);
		this.props.history.push("/error/404");
	}

	componentDidMount = () => {
		const authToken = store("authToken");
		const authUser = store("authUser");
		if (authToken && authUser) {
			this.props.history.push("/user/dashboard");
		}
	};

	render() {
		return (
			<div>
				<meta charSet="utf-8" />
				<title>Strike App for Google</title>
				<meta content="width=device-width, initial-scale=1" name="viewport" />
				<meta content="Webflow" name="generator" />
				<link href="css/normalize.css" rel="stylesheet" type="text/css" />
				<link href="css/webflow.css" rel="stylesheet" type="text/css" />
				<link
					href="css/strike-landing-page-feb-2022.webflow.css"
					rel="stylesheet"
					type="text/css"
				/>
				<style dangerouslySetInnerHTML={{
					__html: `
          @import url(\\css\\normalize.css);
          @import url(\\css\\webflow.css);
          @import url(\\css\\strike-landing-page-feb-2022.webflow.css);


          .w-slider-dot {
            background: teal;
          }
          .w-slider-dot.w-active {
            background: aqua;
          }
        ` }} />
				<span className="af-view">
					<div>
						<div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-3 w-nav">
							<div className="af-class-container-4 w-container">
								<nav role="navigation" className="af-class-nav-menu-3 w-nav-menu">
									<a href="/" className="af-class-nav-link w-nav-link">Overview</a>
									<a href="/about-us" aria-current="page" className="af-class-nav-link w-nav-link w--current">About Us</a>
									<a href="/login" className="af-class-nav-link w-nav-link">Log In</a>
									<a href="/join-waitlist" target="_blank" className="af-class-button-small af-class-_2 w-nav-link">Join Waitlist</a>
								</nav>
								<div className="af-class-menu-button w-nav-button">
									<div className="w-icon-nav-menu" />
								</div>
								<a href="/" className="af-class-strike w-inline-block">
									<img src="images/Strike-Logo-Type_1.svg" loading="lazy" width={98} alt="img"className="af-class-image-14" />
								</a>
							</div>
						</div>
						<div className="af-class-we-are-strike af-class-wf-section">
							<div className="af-class-title">
								<h1 className="af-class-heading-h1 af-class-dark af-class-ledt">Hello, we’re<br />Strike</h1><img src="images/Underline.svg" loading="lazy" alt="img"className="af-class-image-15" />
							</div>
							<p className="af-class-heading---h4">We believe that people need a more productive, cost-effective and insightful tool to conduct their daily virtual work life. Everything from the ease of scheduling meetings to developing a relationship with people you meet virtually has been compromised by existing web-conferencing solutions that were meant for the pre-pandemic world. We have built Strike to bring some of that back.<br /><br />Our goal is to create the ultimate virtual collaboration tool to help you navigate your digital workday without having to hop on a dozen apps and lose productivity.</p>
						</div>
						<div className="af-class-section-9 af-class-wf-section">
							<h1 className="af-class-heading-h1 af-class-dark">Make Your Meetings Awesome.<br />Try Strike.</h1>
							<a href="/join-waitlist" className="af-class-button-large w-button">Join Waitlist</a>
						</div>
						<footer id="footer" className="af-class-footer af-class-wf-section">
							<div className="af-class-container-2 w-container">
								<div className="af-class-footer-flex-container">
									<a href="/" className="af-class-footer-logo-link">
										<img src="images/Strike-Logo-Footer.svg" alt="img"className="af-class-footer-image" />
									</a>
									<div>
										<h2 className="af-class-footer-heading">Product</h2>
										<div className="af-class-line" />
										<ul className="af-class-list w-list-unstyled">
											<li>
												<a href="/" className="af-class-footer-link">Overview</a>
											</li>
											<li>
												<a href="/join-waitlist" className="af-class-footer-link">Join Waitlist</a>
											</li>
										</ul>
									</div>
									<div>
										<h2 className="af-class-footer-heading">Company</h2>
										<div className="af-class-line" />
										<ul className="af-class-list-2 w-list-unstyled">
											<li>
												<a href="/about-us" aria-current="page" className="af-class-footer-link w--current">About Us</a>
											</li>
										</ul>
									</div>
									<div>
										<h2 className="af-class-footer-heading">Help and Support</h2>
										<div className="af-class-line" />
										<ul className="af-class-list-3 w-list-unstyled">
											<li>
												<a href="mailto:support@strikeapp.co?subject=Hello%20Strike%20Team!%20" className="af-class-footer-link">Contact Us</a>
											</li>
										</ul>
									</div>
									<a href="https://www.linkedin.com/company/strike-collaboration" rel="noreferrer" target="_blank" className="w-inline-block"><img src="images/linkedin.svg" loading="lazy" alt="img"/></a>
								</div>
								<div className="af-class-div-block-3">
									<div className="af-class-text-block">Copyright © 2022 All rights reserved Blabberapp, LLC.</div>
									<a href="/privacypolicy" className="af-class-footer-link af-class-_2">Terms of Use</a>
									<div className="af-class-div-block-4" />
									<a href="/privacypolicy" className="af-class-footer-link af-class-_2">Privacy Policy</a>
								</div>
							</div>
						</footer>
						{/* [if lte IE 9]><![endif] */}
					</div>
				</span>
			</div>
		);
	}
}
export default connect((state: any) => ({
	error: state.auth.loginError,
	loading: state.auth.loginBusy,
	user: state.auth.user,
}))(about);

type propTypes = {
	history: any;
	dispatch: any;
	user: any;
	error: any;
	terms: number;
	loading: boolean;
};
type stateType = {};
import config from '../../clientConfig';
const modulePath: string = "subscription/";
const apiUrl = config.apiUrl + modulePath;

const SUBSCRIPTION = `${modulePath}SUBSCRIPTION`;
const SUBSCRIPTION_SUCCESS = `${modulePath}SUBSCRIPTION_SUCCESS`;
const SUBSCRIPTION_FAIL = `${modulePath}SUBSCRIPTION_FAIL`;
const INVOICE_LISTING = `${modulePath}INVOICE_LISTING`;
const INVOICE_LISTING_FAIL = `${modulePath}INVOICE_LISTING_FAIL`;
const INVOICE_LISTING_SUCCESS = `${modulePath}INVOICE_LISTING_SUCCESS`;

const initialState = {
	subscription: null,
	subscriptionBusy: false,
	subscriptionError: null,
	invoiceListing: [],
	invoiceListingBusy: false,
	invoiceListingError: null,
	// userInvoice: null
};


export default function reducer(state: any = initialState, action: any) {
	switch (action.type) {
		case SUBSCRIPTION:
			return { ...state, subscriptionBusy: true, subscriptionError: null };

		case SUBSCRIPTION_SUCCESS:
			return { ...state, subscriptionBusy: false, subscriptionError: null, subscription: action.subscription };

		case SUBSCRIPTION_FAIL:
			return { ...state, subscriptionBusy: false, subscriptionError: action.error };

		case INVOICE_LISTING:
			return { ...state, invoiceListingBusy: true, invoiceListingError: false };

		case INVOICE_LISTING_SUCCESS:
			return { ...state, invoiceListingBusy: false, invoiceListingError: false, invoiceListing: action };

		case INVOICE_LISTING_FAIL:
			return { ...state, invoiceListingBusy: false, invoiceListingError: true };

		default:
			return state
	}
}

export const subscribeUser = (params: any) => async (dispatch, getState, api) => {
	try {
		// dispatch({type: SUBSCRIPTION})
		const result = await api.post(apiUrl + `subscribe-user`, { data: params })
		if (!result) {
			return false;
		}
		// dispatch({
		//   type: SUBSCRIPTION_SUCCESS,
		//   subscription: result.data,
		//   subscriptionBusy: false, 
		//   subscriptionError: null
		// })

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			// dispatch({
			//   type: SUBSCRIPTION_FAIL,
			//   subscription: null,
			//   subscriptionBusy: false, 
			//   subscriptionError: error
			// })
		}
		console.error(error)
	}
}


export const invoiceListing = (params: any) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: SUBSCRIPTION })
		const result = await api.post(apiUrl + `list-user-invoices`, { data: params })
		if (!result) {
			return false;
		}
		dispatch({
			type: INVOICE_LISTING_SUCCESS,
			invoiceListing: result.data?.list,
			invoiceListingBusy: false,
			invoiceListingError: null
		})

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			dispatch({
				type: INVOICE_LISTING_FAIL,
				invoiceListing: null,
				invoiceListingBusy: false,
				invoiceListingError: error
			})
		}
	}
}

export const getUserSubscription = (params: any) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `get-user-subscription`, { data: params })
		if (!result) {
			return false;
		}
		dispatch({
			subscription: result.data,
			subscriptionBusy: false,
			subscriptionError: null,
			type: SUBSCRIPTION_SUCCESS,
		})
 
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			dispatch({
				type: SUBSCRIPTION_FAIL,
				subscription: null,
				subscriptionBusy: false,
				subscriptionError: error
			})
		}
		return error
	}
}

export const cancelUserSubscription = (params: any) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: SUBSCRIPTION })
		const result = await api.post(apiUrl + `cancel-user-subscription`, { data: params })
		if (!result) {
			return false;
		}
		dispatch({
			type: SUBSCRIPTION_SUCCESS,
			subscription: result.data,
			subscriptionBusy: false,
			subscriptionError: null
		})

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			dispatch({
				type: SUBSCRIPTION_FAIL,
				subscription: null,
				subscriptionBusy: false,
				subscriptionError: error
			})
		}
		return error
	}
}


export const getUserInvoice = (params: any) => async (dispatch, getState, api) => {
	try {
		// dispatch({type: SUBSCRIPTION})
		const result = await api.post(apiUrl + `get-user-invoice`, { data: params })
		if (!result) {
			return false;
		}
		// dispatch({
		//   type: SUBSCRIPTION_SUCCESS,
		//   subscription: result.data,
		//   subscriptionBusy: false, 
		//   subscriptionError: null
		// })

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			// dispatch({
			//   type: SUBSCRIPTION_FAIL,
			//   subscription: null,
			//   subscriptionBusy: false, 
			//   subscriptionError: error
			// })
		}
		return error
	}
}
// add-payment-method
export const addPaymentMethod = (params: any) => async (dispatch, getState, api) => {
	try {
		// dispatch({type: SUBSCRIPTION})
		const result = await api.post(apiUrl + `add-payment-method`, { data: params })
		if (!result) {
			return false;
		}
		// dispatch({
		//   type: SUBSCRIPTION_SUCCESS,
		//   subscription: result.data,
		//   subscriptionBusy: false, 
		//   subscriptionError: null
		// })

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
			// dispatch({
			//   type: SUBSCRIPTION_FAIL,
			//   subscription: null,
			//   subscriptionBusy: false, 
			//   subscriptionError: error
			// })
		}
		return error
	}
}

export const updateUserCard = (params: any) => async (dispatch, getState, api) => {
	try {
		// dispatch({type: SUBSCRIPTION})
		const result = await api.post(apiUrl + `update-user-card`, { data: params })
		if (!result) {
			return false;
		}
		// dispatch({
		//   type: SUBSCRIPTION_SUCCESS,
		//   subscription: result.data,
		//   subscriptionBusy: false, 
		//   subscriptionError: null
		// })

		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateUserCard", errObj);
			// dispatch({
			//   type: SUBSCRIPTION_FAIL,
			//   subscription: null,
			//   subscriptionBusy: false, 
			//   subscriptionError: error
			// })
			throw errObj;
		}
		console.error("updateUserCard", error);
		throw error;
	}
}

export const flushSubscription = () => async (dispatch, getState, api) => {
	try {
	 dispatch({
		  type: SUBSCRIPTION_SUCCESS,
		  subscription: null,
		  subscriptionBusy: false, 
		  subscriptionError: null
		})

	} catch (error) {
	 console.error(error)
	}
}
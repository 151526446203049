import superagent from 'superagent';
import config from '../../clientConfig';

const apiUrl = config.apiUrl + 'invite/';

const INVITE = 'Invite/INVITE';
const INVITE_SETTINGS = 'Invite/SETTINGS';
const CALENDAR_SETTINGS = 'Invite/CALENDAR_SETTINGS';

const INVITE_FAIL = 'Invite/INVITE_FAIL';
const INVITE_SUCCESS = 'Invite/INVITE_SUCCESS';
const initialState = {
  InviteBusy: false,
  InviteError: false,
  Invite: null,
  slotsData: null

};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case INVITE:
      return { ...state, InviteBusy: true, inviteError: null };
    case INVITE_SETTINGS:
      return { ...state, InviteBusy: true, InviteError: null, InviteSettings: action.inviteSettings };
    case CALENDAR_SETTINGS:
      return { ...state, InviteBusy: true, CalendarSettings: action.calendarsettings };
    case INVITE_FAIL:
      return { ...state, InviteBusy: false, InviteSettings: null, inviteError: action.error };
    case INVITE_SUCCESS:
      return { ...state, InviteBusy: false, slotsData: action.slotsData };
    default:
      return state;
  }
}



export const GetSettings = (email) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await api.get(apiUrl + `get-settings?email=` + email);
    dispatch({ type: INVITE_SETTINGS, inviteSettings: response.data });

    return response.data; // return success to frontend
  } catch (error) {
    console.error("inviteSettings", error);
    dispatch({ type: INVITE_FAIL, error: error });
  }
};

export const msallogin = (url, type) => async (dispatch, getState, api) => {

  try {
    const result = await superagent.get(apiUrl + `login-calendar?url=${url}&&type=${type}`)
      .send()
      .set({ 'Content-Type': 'application/json' });
    var response = result.body;


    return response.data; // return success to frontend
  } catch (error) {
    const errObj = error.response.body;
    errObj.type = "error";
  }
};


export const personalSettings = (settings) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });

  try {
    const response = await superagent.post(apiUrl + `personal-settings`)
      .send(settings)
      .set({ 'Content-Type': 'application/json' });
    var result = response.body;

    dispatch({ type: INVITE_SETTINGS, inviteSettings: result.data });
    return result.data;
  } catch (error) {
    const errObj = error.response.body;
    errObj.type = "error";
    dispatch({ type: INVITE_FAIL, error: errObj });
  }
};

export const CalendarSettingsData = (settings) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });

  try {
    dispatch({ type: CALENDAR_SETTINGS, calendarsettings: settings });
    return true;
  } catch (error) {
    const errObj = error.response.body;
    errObj.type = "error";
    dispatch({ type: INVITE_FAIL, error: errObj });
  }
};

export const SetupCalendarSettings = (settings) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await superagent.post(apiUrl + `calendar-settings`)
      .send(settings)
      .set({ 'Content-Type': 'application/json' });
    var result = response.body;
    dispatch({ type: CALENDAR_SETTINGS, calendarsettings: settings });
    return result.data;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      errObj.type = "error";
      // console.log('errObj', errObj);
      dispatch({ type: INVITE_FAIL, error: errObj });
      return errObj;
    }
    console.error(error);
  }
}
export const AvailabilityCalendarSettings = (settings) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });

  try {
    const response = await superagent.post(apiUrl + `availability-settings`)
      .send(settings)
      .set({ 'Content-Type': 'application/json' });
    var result = response.body;
    dispatch({ type: INVITE_SETTINGS, inviteSettings: result.data });
    return result.data;
  } catch (error) {
    const errObj = error.response.body;
    errObj.type = "error";
    dispatch({ type: INVITE_FAIL, error: errObj });
  }
};

export const getPreferredSlots = (email: string, time: string, day: string) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await api.get(apiUrl + `get-preferred_slots?email=${email}&time=${time}&day=${day}`);

    dispatch({ type: INVITE_SUCCESS, user: response.data });
    return response.data;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: INVITE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
  }
};

export const getSlots = (email: string, time: string) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await api.get(apiUrl + `get-slots?email=${email}&time=${time}`);

    dispatch({ type: INVITE_SUCCESS, user: response.data });
    return response.data;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: INVITE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
    console.error("slots", error);
  }
};

export const getBusySlots = (email: string, date: string, time: string) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await api.get(apiUrl + `get-busy-slots?email=${email}&time=${time}&date=${date}`);

    dispatch({ type: INVITE_SUCCESS, user: response.data });
    return response.data;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: INVITE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
    console.error(error);
  }
};


export const finduser = (email) => async (dispatch, getState, api) => {
  try {
    const result = await superagent.get(apiUrl + `get-settings?email=` + email)
      .send()
      .set({ 'Content-Type': 'application/json' });
    var response = result.body;
    return response.data; // return success to frontend
  } catch (error) {
    const errObj = error.response.body;
    errObj.type = "error";
  }
};



export const disconnectCalendar = (data) => async (dispatch, getState, api) => {
  dispatch({ type: INVITE });
  try {
    const response = await superagent.post(apiUrl + `disconnect-calendar`)
    .send(data)
    .set({'Content-Type': 'application/json'});

    dispatch({ type: INVITE_SUCCESS, user: response.body });
    return response.body;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: INVITE_FAIL, error: errObj });
      return errObj; // return success to frontend
    }
  }
};

export const mailchimpRegister= (params) => async (dispatch, getState, api) => {
   
  try { 
    const response = await superagent.get(apiUrl + `mailchimp-register?email=${params.email}`)
    return response;
  } catch(err){
    console.error(err)
  }
}

export const waitlistRegister = (params) => async (dispatch, getState, api) => {
  try { 
    // console.log("waitlistRegister.params", params);
    const response = await api.post(apiUrl + `waitlist-register`, { data: params})
    return response.data;
  } catch(err){
    console.error('waitlistRegister',err);
    return err.response.body;
  }
}
import config from '../../clientConfig';
const modulePath = "users/";
const apiUrl = config.apiUrl + modulePath;

const USER = `${modulePath}USER`;
const USER_SUCCESS = `${modulePath}USER_SUCCESS`;
const USER_FAIL = `${modulePath}USER_FAIL`;
const USER_FLUSH = `${modulePath}USER_FLUSH`;
const TEMPLATE_DATA = `${modulePath}TEMPLATE_DATA`;
const TEMPLATE_DATA_SUCCESS = `${modulePath}TEMPLATE_DATA_SUCCESS`;
const TEMPLATE_DATA_FAIL = `${modulePath}TEMPLATE_DATA_FAIL`;
const INVITE_DATA = `${modulePath}INVITE_DATA`;
const INVITE_DATA_SUCCESS = `${modulePath}INVITE_DATA_SUCCESS`;
const INVITE_DATA_FAIL = `${modulePath}INVITE_DATA_FAIL`;
const DIGICARD = `${modulePath}DIGICARD`;
const DIGICARD_SUCCESS = `${modulePath}DIGICARD_SUCCESS`;
const DIGICARD_FAIL = `${modulePath}DIGICARD_FAIL`;
const HIDE_ALERTS = `${modulePath}HIDE_ALERTS`;

const initialState = {
	digicardBusy: false,
	digicardError: false,
	digicard: null,
	user: null,
	userBusy: false,
	userError: null,
	templateData: null,
	templateDataBusy: null,
	templateDataError: null,
	inviteData: null,
	inviteDataBusy: false,
	inviteDataError: null,
};

export default function reducer(state: any = initialState, action: any) {
	switch (action.type) {
		case USER:
			return { ...state, userBusy: true, userError: null };
		case USER_SUCCESS:
			return { ...state, userBusy: false, user: action.user };
		case USER_FAIL:
			return { ...state, userBusy: false, userError: action.error };
		case USER_FLUSH:
			return { ...state, userBusy: false, userError: null, user: null };

		case TEMPLATE_DATA:
			return { ...state, templateDataBusy: true, templateDataError: null };
		case TEMPLATE_DATA_SUCCESS:
			return { ...state, templateDataBusy: false, templateData: action.templateData };
		case TEMPLATE_DATA_FAIL:
			return { ...state, templateDataBusy: true, templateData: null, templateDataError: action.error };

		case INVITE_DATA:
			return { ...state, inviteDataBusy: true, inviteDataError: null };
		case INVITE_DATA_SUCCESS:
			return { ...state, inviteDataBusy: false, inviteData: action.inviteData };
		case INVITE_DATA_FAIL:
			return { ...state, inviteDataBusy: true, inviteData: null, inviteDataError: action.error };

		case DIGICARD:
			return { ...state, digicardBusy: true, digicardError: null };
		case DIGICARD_SUCCESS:
			return { ...state, digicardBusy: false, digicard: action.digicard };
		case DIGICARD_FAIL:
			return { ...state, digicardBusy: false, digicard: null, digicardError: action.error };

		case HIDE_ALERTS:
			return { ...state, userError: null, digicardError: null, templateDataError: null };

		default:
			return state;
	}
}

export const getUser = (id: string, searchBy = null, filterBy = null) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.get(apiUrl + `get-user-profile?id=${id}`);

		dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: error.message });
		console.error("getUser", error);
	}
};


export const getDefaultTemplates = (userId) => async (dispatch, getState, api) => {
	dispatch({ type: TEMPLATE_DATA });
	try {
		const response = await api.get(apiUrl + `get-default-templates?userId=${userId}`);
		dispatch({ type: TEMPLATE_DATA_SUCCESS, templateData: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: TEMPLATE_DATA_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("getDefaultTemplatesError", error);
	}
};

export const getDefaultInvites = (userId) => async (dispatch, getState, api) => {
	dispatch({ type: INVITE_DATA });
	try {
		const response = await api.get(apiUrl + `get-default-invites?userId=${userId}`);
		dispatch({ type: INVITE_DATA_SUCCESS, inviteData: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: INVITE_DATA_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("getDefaultTemplatesError", error);
	}
};

export const createNewTemplate = (templateData) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `create-new-template`, { data: templateData })

		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewTemplate", errObj);
		}
	}
}

export const deleteTemplate = (templateId) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `delete-template?id=${templateId}`)
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("deleteTemplate", errObj);
		}

	}
}

export const deleteInvite = (id) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-status`, { data: { id, status: 'deleted' } })
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteStatus", errObj);
		}
	}
}

export const updateTemplateStatus = (id, status) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-template`, { data: { id, status } })
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateStatus", errObj);
		}
	}
}

export const updateTempDuration = (id, duration) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-template-duration`, { data: { id, duration } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTempDuration", errObj);
		}
	}
}

export const updateTempTitle = (id, title, slug) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-template-title`, { data: { id, title, slug } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTempTitle", errObj);
		}
	}
}

export const updateInviteTitle = (id, title, slug) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-title`, { data: { id, title, slug } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteTitle", errObj);
		}
	}
}

export const getTemplateDataById = (id) => async (dispatch, getState, api) => {
	dispatch({ type: TEMPLATE_DATA });
	try {
		const response = await api.get(apiUrl + `get-template-by-id?id=${id}`);
		dispatch({ type: TEMPLATE_DATA_SUCCESS, templateData: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: TEMPLATE_DATA_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("getTemplateDataById", error);
	}
};

export const getInviteDataById = (id) => async (dispatch, getState, api) => {
	dispatch({ type: TEMPLATE_DATA });
	try {
		const response = await api.get(apiUrl + `get-invite-by-id?id=${id}`);
		dispatch({ type: TEMPLATE_DATA_SUCCESS, templateData: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: TEMPLATE_DATA_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("getInviteDataById", error);
	}
};

export const getTemplateDataBySlug = (id, slug) => async (dispatch, getState, api) => {
	dispatch({ type: TEMPLATE_DATA });
	try {
		const response = await api.get(apiUrl + `get-template-by-slug?id=${id}&slug=${slug}`);
		dispatch({ type: TEMPLATE_DATA_SUCCESS, templateData: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: TEMPLATE_DATA_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("getDefaultTemplatesError", error);
	}
};

export const updateInviteForParticipants = (id, participants, isResheduled) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-participants`, { data: { id, participants, isResheduled } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForParticipants", errObj);
		}
	}
}

export const updateTemplateForSlots = (id, slots) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-template-Data`, { data: { id, slots } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForSlots", errObj);
		}
	}
}

export const updateInviteData = (id, slots) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-Data`, { data: { id, slots } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteData", errObj);
		}
	}
}

export const createNewInvite = (params) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `create-new-invite`, { data: { params } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewInvite", errObj);
			return errObj;
		}
		console.error("createNewInvite", error);
	}
}

export const getSignedUrl = (params) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `get-signed-url`, { data: params });
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("getSignedUrl", errObj);
			return errObj;
		}
		console.error("getSignedUrl", error);
	}
}

export const updateUserProfile = (params: any) => async (dispatch, getState, api) => {
	dispatch({ type: DIGICARD });
	try {
		const response = await api.post(apiUrl + `update-user-profile`, { data: { params } });

		dispatch({ type: DIGICARD_SUCCESS, digicard: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: DIGICARD_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("updateUserProfile", error);
	}
}

export const removeContact = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.post(apiUrl + `delete-user-contact`, { data: params });

		// dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("removeContact", error);
	}
};

export const updateContacts = (filteredContacts) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const user = getState().users.user;
		//console.error(user);
		user.contacts = filteredContacts;

		dispatch({ type: USER_SUCCESS, user: user });
		return true; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
		}
		console.error("updateContacts", error);
	}
}

export const addUserContactNote = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.post(apiUrl + `add-user-contact-note`, { data: params });

		dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: "Something went wrong!" });
		console.error("addUserContactNote", error);
	}
};

export const hideAlerts = () => async (dispatch, getState, api) => {
	dispatch({ type: HIDE_ALERTS });
};

export const updateCurrentUser = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER_SUCCESS, user: params.user });
}

export const exchangeDigicard = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.post(apiUrl + `exchange-digicard`, { data: params });
		console.error('response', response);
		// dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: "Something went wrong!" });
		console.error("exchangeDigicard", error);
	}
};

export const initialLogin = (user_id: string) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + 'set-initial-login', {data: {user_id: user_id}})
		dispatch({ type: USER_SUCCESS, user: response.data });

		return response.data

	} catch (error){
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: "Something went wrong!" });
		console.error("initialLogin", error);
		return error;
	}
}

export const acceptDigicard = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.post(apiUrl + `accept-digicard`, { data: params });

		// dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: "Something went wrong!" });
		console.error("acceptDigicard", error);
		return error;
	}
};

export const rejectDigicard = (params) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.post(apiUrl + `reject-digicard`, { data: params });

		// dispatch({ type: USER_SUCCESS, user: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: "Something went wrong!" });
		console.error("rejectDigicard", error);
	}

};

export const sendInviteMail = (id, location) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `send-invite-email`, { data: { id: id, location: location } });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("rejectDigicard", error);
	}
};

export const sendReminderMail = (id, location) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `send-reminder-email`, { data: { id: id, location: location } });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("rejectDigicard", error);
	}
};

export const sendScheduledMeetingReminder = (id, user, url, time) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `send-scheduled-reminder-email`, { data: { id: id, user: user, url: url, timestamp: time } });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("rejectDigicard", error);
	}
};

export const flushUser = () => async (dispatch, getState, api) => {
	dispatch({ type: USER_FLUSH });
};

export const getContacts = (id: string, searchBy = null, filterBy = null, limit = null, page = null) => async (dispatch, getState, api) => {
	dispatch({ type: USER });
	try {
		const response = await api.get(apiUrl + `get-user-contacts?id=${id}&search_by=${searchBy}&filter_by=${filterBy}&limit=${limit}&page=${page}`);
		const user = getState().users.user;
		user.contacts = response.data?.contacts;
		user.totalContacts = response.data?.totalContacts;
		dispatch({ type: USER_SUCCESS, user: user });
		return user;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: USER_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		dispatch({ type: USER_FAIL, error: error.message });
		console.error("getUser", error);
	}
};

export const removeProfileImage = (id: string) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `remove-profile-image`, { data: {id: id} });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("removeProfileImage", errObj);
			return false;
		}
		console.error("removeProfileImage", error);
	}
};

export const updateInviteParticipants = (id, participants) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-for-participants`, { data: { id, participants } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForParticipants", errObj);
		}
	}
}

export const updateUserRedux = (params: any) => async (dispatch, getState, api) => {
		dispatch({ type: USER_SUCCESS, user: params });
}

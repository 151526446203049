import superagent from 'superagent';
import store from 'store2';
import config from '../../clientConfig';
import { toastr } from 'react-redux-toastr';

const modulePath = "meeting/";
const apiUrl = config.apiUrl + modulePath;

const MEETING = `${modulePath}MEETING`;
const MEETING_USER = `${modulePath}USER`;
const MEETING_QUICK = `${modulePath}QUICK`;
const MEETING_SUCCESS = `${modulePath}MEETING_SUCCESS`;
const MEETING_FAIL = `${modulePath}MEETING_FAIL`;
const MESSAGING = `${modulePath}MESSAGING`;
const MESSAGING_SUCCESS = `${modulePath}MESSAGING_SUCCESS`;
const MESSAGING_FAIL = `${modulePath}MESSAGING_FAIL`;
const DIGICARD = `${modulePath}DIGICARD`;
const DIGICARD_SUCCESS = `${modulePath}DIGICARD_SUCCESS`;
const DIGICARD_FAIL = `${modulePath}DIGICARD_FAIL`;
const PARTICIPANTS = `${modulePath}PARTICIPANTS`;
const SCHEDULE = `${modulePath}SCHEDULE`;
const PARTICIPANTS_SUCCESS = `${modulePath}PARTICIPANTS_SUCCESS`;
const PARTICIPANTS_FAIL = `${modulePath}PARTICIPANTS_FAIL`;
const UPCOMINGEVENTS = `${modulePath}UPCOMINGEVENTS`;
const HISTORYEVENTS = `${modulePath}HISTORYEVENTS`;
const INVITE = `${modulePath}invite`;
const INVITE_DATA_REDUX = `${modulePath}INVITE_DATA_REDUX`;
const ATTENDEE = `${modulePath}ATTENDEE`;
const ATTENDEE_SUCCESS = `${modulePath}ATTENDEE_SUCCESS`;
const ATTENDEE_FAIL = `${modulePath}ATTENDEE_FAIL`;
const SET_MEETING_SESSION = `${modulePath}SET_MEETING_SESSION`;
const RECORDING = `${modulePath}RECORDING`;
const DIGICARD_DATA_REDUX = `${modulePath}DIGICARD_DATA_REDUX`;
const MEETING_RESET = `${modulePath}RESET`;
const START_CONVERSATION = `${modulePath}START_CONVERSATION`
const SET_DIGICARD_STATUS = `${modulePath}SET_DIGICARD_STATUS`
const SET_USER_MEETINGS = `${modulePath}SET_USER_MEETINGS`
const SET_USER_MEETINGS_SUCCESS = `${modulePath}SET_USER_MEETINGS_SUCCESS`
const SET_MEETING_NOTE = `${modulePath}SET_MEETING_NOTE`

const MEET_USER = `${modulePath}MEET_USER`;
const initialState = {
	meetingBusy: false,
	meetingError: false,
	meeting: null,
	messagingBusy: false,
	messagingError: false,
	messaging: null,
	digicardBusy: false,
	digicardError: false,
	digicard: null,
	participantBusy: false,
	participantError: false,
	participant: null,
	schedulemeeting: null,
	schedulemeetingBusy: false,
	schedulemeetingError: false,
	upcomingevents: null,
	upcomingeventsBusy: false,
	upcomingeventsError: false,
	historyevents: null,
	historyeventsBusy: false,
	historyeventsError: false,
	inviteBusy: false,
	inviteError: false,
	invite: null,
	meetingSession: null,
	isRecordingConsent: false,
	user: null,
	digiCardStatus: {},
	userMeetings: [],
	userMeetingsLoading: false,
	note: false,
	AttendeeError: null,
	AttendeeBusy: false,
	Attendee: null,
};

export default function reducer(state: any = initialState, action: any) {
	switch (action.type) {
		case MEETING:
			return { ...state, meetingBusy: true, meetingError: null };
		case MEETING_USER:
			return { ...state, meetingUserBusy: true, meetingUser: action.meetingUser };
		case MEETING_QUICK:
			return { ...state, meetingUserBusy: true, meetingQuick: action.meetingQuick };
		case MEETING_SUCCESS:
			return { ...state, meetingBusy: false, meetingError: null, meeting: action.meeting };

		case MEETING_FAIL:
			return { ...state, meetingBusy: false, meeting: null, meetingError: action.error };

		case MESSAGING:
			return { ...state, messagingBusy: true, messagingError: null };

		case MESSAGING_SUCCESS:
			return { ...state, messagingBusy: false, messaging: action.messaging };

		case MESSAGING_FAIL:
			return { ...state, messagingBusy: false, messaging: null, messagingError: action.error };

		case DIGICARD:
			return { ...state, digicardBusy: true, digicardError: null };

		case DIGICARD_SUCCESS:
			return { ...state, digicardBusy: false, digicard: action.digicard };

		case DIGICARD_FAIL:
			return { ...state, digicardBusy: false, digicard: null, digicardError: action.error };

		case PARTICIPANTS:
			return { ...state, participantBusy: true, participantError: null };

		case PARTICIPANTS_SUCCESS:
			return { ...state, participantBusy: false, participant: action.participant };

		case PARTICIPANTS_FAIL:
			return { ...state, participantBusy: false, participant: null, participantError: action.error };
		case SCHEDULE:
			return { ...state, schedulemeetingBusy: false, schedulemeeting: action.schedulemeeting };
		case UPCOMINGEVENTS:
			return { ...state, upcomingeventsBusy: false, upcomingevents: action.upcomingevents };
		case HISTORYEVENTS:
			return { ...state, historyeventsBusy: false, historyevents: action.historyevents };
		case INVITE:
			return { ...state, inviteBusy: false, invite: action.invite };
		case INVITE_DATA_REDUX:
			return { ...state, invitereduxBusy: false, inviteredux: action.inviteredux };
		case ATTENDEE:
			return { ...state, AttendeeBusy: true, AttendeeError: null };
		case ATTENDEE_SUCCESS:
			return { ...state, AttendeeBusy: false, AttendeeError: null, Attendee: action.attendee };
		case ATTENDEE_FAIL:
			return { ...state, AttendeeBusy: false, Attendee: null, AttendeeError: action.error };
		case SET_MEETING_SESSION:
			return { ...state, meetingSession: action.payload };
		case RECORDING:
			return { ...state, RecordingConsent: action.Consent };
		case DIGICARD_DATA_REDUX:
			return { ...state, digicardredux: action.digicardredux };
		case MEETING_RESET:
			return { ...state, digicardredux: null, RecordingConsent: null, meeting: null, meetingUser: null };
		case START_CONVERSATION:
			return { ...state, conversationData: action.conversationData };
		case SET_DIGICARD_STATUS:
			return { ...state, digiCardStatus: action.payload };
		case SET_MEETING_NOTE:
			return { ...state, note: action.payload };
		case MEET_USER:
			return { ...state, user: action.payload };
		case SET_USER_MEETINGS:
			return { ...state, userMeetingsLoading: true };
		case SET_USER_MEETINGS_SUCCESS:
			return { ...state, userMeetings: action.payload, userMeetingsLoading: false };
		default:
			return state;
	}
}

// getMeetingUrl api call from reducer
//#region actions
export const getMeetingUrl = (userId, timezone) => async (dispatch, getState, api) => {
	dispatch({ type: MEETING });
	try {
		const response = await superagent.get(apiUrl + `get-meeting-url?userId=${userId}&timezone=${timezone}`)
		.send()
		.set({ 'Content-Type': 'application/json' });
		let result = response.body;
		dispatch({ type: MEETING_SUCCESS, meeting: result.data });
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("meetinUrl", error);
	}
};

export const getMeetingUser = (meetingTitle) => async (dispatch, getState, api) => {
	try {
		const result = await superagent.get(apiUrl + `get-meeting?meetingId=` + meetingTitle)
			.send()
			.set({ 'Content-Type': 'application/json' });
		var response = result.body;
		dispatch({ type: MEETING_USER, meetingUser: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			//  dispatch({ type: MEETING_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("meetinUrl", error);
	}
};

export const getMeetingsByUser = (info) => async (dispatch, getState, api) => {

	try {
		const response = await superagent.get(apiUrl + 'meetings-by-user?id=' + info)
		const responseData = response.body 
		return responseData;
	} catch (error) {
		if (error.response !== undefined) {
			return
		}
		console.error("meetingData", error);
	}
}
export const updateMeetingData = (meeting) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: MEETING_SUCCESS, meeting: meeting });
		return true; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const validateMeetingUrl = (id, pwd) => async (dispatch, getState, api) => {
	try {
		const result = await superagent.get(apiUrl + `verify?ID=` + id + `&PWD=` + pwd)
			.send()
			.set({ 'Content-Type': 'application/json' });
		var response = result.body;
		dispatch({ type: MEETING_USER, meetingUser: response.data.meetingData });
		return result.body;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const storeMeetingData = (meeting) => async (dispatch, getState, api) => {
	dispatch({ type: MEETING });
	try {
		const response = await superagent.post(apiUrl + `meetings`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;
		dispatch({ type: MEETING_SUCCESS, meeting: result });
		if (!result) {
			dispatch({ type: MEETING_FAIL, error: 'No data received' });
			return false;
		}
		const meetingData = {
			userName: `${meeting.FirstName} ${meeting.LastName}`,
			meetingTitle: meeting.MeetingTitle
		}
		return meetingData; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const fetchAttendeeMeetingData = (attendee) => async (dispatch, getState, api) => {
	dispatch({ type: ATTENDEE });
	try {
		const response = await superagent.post(apiUrl + `meetings-attendees`)
			.send(attendee)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			dispatch({ type: ATTENDEE_FAIL, error: 'No data received' });
			return false;
		}
		const user = getState().meeting.user;
		user.contacts = result.data?.contacts;
		dispatch({ type: ATTENDEE_SUCCESS, attendee: user });
		return result.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: ATTENDEE_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const getMeetingParticipants = (params) => async (dispatch, getState, api) => {
	dispatch({ type: MESSAGING });
	try {
		const response = await superagent.post(apiUrl + `get-meeting-participants`)
			.send(params)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			dispatch({ type: MESSAGING_FAIL, error: 'No data received' });
			return false;
		}
		dispatch({ type: MESSAGING_SUCCESS, messaging: result.data });
		return result.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MESSAGING_FAIL, error: errObj });
		}
		console.error("describeChannel", error);
	}
}

export const createMeeting = (meeting) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(apiUrl + `create-meeting`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			return false;
		}
		return result.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error(errObj);
			return errObj;
		}
		console.error("createMeeting", error);
	}
}


export const createScheduleMeeting = (meeting) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(apiUrl + `create-schedule-meeting`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			//  dispatch({ type: MESSAGING_FAIL, error: 'No data received' });
			return false;
		}
		// dispatch({ type: MEETING_QUICK, meetingQuick: response.body });
		return result.data; // return success to frontend
		//return meetingData; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			// dispatch({ type: MEETING_FAIL, error: errObj });
			console.error(errObj);
			return errObj; // return success to frontend
		}
		console.error("createMeeting", error);
	}
}

export const getUrlScheduleMeeting = () => async (dispatch, getState, api) => {
	try {
		const response = await superagent.get(apiUrl + `get-url-schedule`)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			//  dispatch({ type: MESSAGING_FAIL, error: 'No data received' });
			return false;
		}
		// dispatch({ type: MEETING_QUICK, meetingQuick: response.body });
		return result.data; // return success to frontend
		//return meetingData; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
		}
		console.error("createMeeting", error);
	}
}

export const droppedFromMeeting = (meeting) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(apiUrl + `dropped-from-meeting`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			return false;
		}
		return result.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			console.error(error.response.body);
		}
		console.error("droppedFromMeeting", error);
	}
}
export const endMeeting = (meeting) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(apiUrl + `end-meeting`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			return false;
		}
		return result.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			console.error(error.response.body);
		}
		console.error("droppedFromMeeting", error);
	}
}

export const exchangeDigicard = (user) => async (dispatch, getState, api) => {
	dispatch({ type: DIGICARD });

	try {
		const response = await superagent.post(apiUrl + `exchange-digicard`)
			.send(user)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result || !result.status) {
			dispatch({ type: DIGICARD_FAIL, error: 'No data received' });
		}

		dispatch({ type: DIGICARD_SUCCESS, digicard: result.data });
		return true;
	} catch (error) {
		if(error.response) {
			const errObj = error.response.body;
			dispatch({ type: DIGICARD_FAIL, error: errObj });
			console.error(errObj);
		}
	}
}

export const fetchUserMeetings = () => async (dispatch, getState, api) => {
	dispatch({ type: MEETING });
	try {
		const user = store('authUser');
		const response = await api.get(apiUrl + `meetings?user_id=${user.id}`);

		if (!response) {
			dispatch({ type: MEETING_FAIL, error: 'No data received' });
			return false;
		}
		dispatch({ type: MESSAGING_SUCCESS, messaging: response.data });
		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const chimeMeetingJoin = (fetchMeetingURI: string) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(fetchMeetingURI)
			.send()
			.set({ 'Content-Type': 'application/json' });

		return response.body;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj
		}
		console.error("fetchChimeMeeting", error);
	}
}
export const chimeMeetingEnd = (fetchMeetingURI: string) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.get(fetchMeetingURI)
		return response;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj
		}
		console.error("chimeMeetingEnd", error);
	}
}

export const getUserDetailsByUsername = (username: string) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-user-by-username?username=${username}`);

		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("userData", error);
	}
};


export const getDefaultTemplates = (userId) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-default-templates?userId=${userId}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("getDefaultTemplatesError", error);
	}
};

export const getInviteDataBySlug = (id, slug) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-template-by-slug?id=${id}&slug=${slug}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("getInviteDataBySlug", error);
	}
};

export const getTemplateDataBySlug = (id, slug) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-template-slug?id=${id}&slug=${slug}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("getInviteDataBySlug", error);
	}
};

export const acceptDigicard = (params) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `accept-digicard`, { data: params });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("acceptDigicard", error);
	}
};

export const rejectDigicard = (params) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `reject-digicard`, { data: params });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("rejectDigicard", error);
	}
};
export const updateParticipantsSlots = (id, email, slots, username) => async (dispatch, getState, api) => {
	dispatch({ type: PARTICIPANTS });
	try {
		const response = await api.post(apiUrl + `update-participants-slots`, { data: { id, email, slots, username } });

		dispatch({ type: PARTICIPANTS_SUCCESS, digicard: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: PARTICIPANTS_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("updateParticipants", error);
		return error
	}
}

export const updateParticipantsSlotsOptions = (data) => async (dispatch, getState, api) => {
	dispatch({ type: INVITE });
	try {
		dispatch({ type: INVITE, invite: data });
		return true; // return success to frontend
	} catch (error) {


	}
}

export const sendEmailToHOstForAnotherDay = (host, participant, meetingTitle) => async (dispatch, getState, api) => {
	dispatch({ type: PARTICIPANTS });
	try {
		const response = await api.post(apiUrl + `send-email-to-host-for-another-day`, { data: { host, participant, meetingTitle } });

		dispatch({ type: PARTICIPANTS_SUCCESS, digicard: response.data });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: PARTICIPANTS_FAIL, error: errObj });
			return errObj; // return success to frontend
		}
		console.error("updateParticipants", error);
	}


}

export const sendWelcomeEmail = (email, name, hostId) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `send-welcome-email`, { data: { email, name, hostId } });

		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("updateParticipants", error);
	}
};

export const sendTranscriptionEmail = (meeting_url) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `send-transcription-email`, { data: { meeting_url } });

		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("updateParticipants", error);
	}
};

export const getTemplateDataById = (id) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-template-by-id?id=${id}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("userData", error);
	}
};
export const getUserByEmail = (email: string) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-user-by-email?email=${email}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("getTemplateDataById", error);
	}
};

export const addMeetingUser = (params: any) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `add-meeting-user`, { data: params });

		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("userData", error);
	}
}
export const getUpcomingEvents = (id) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-upcoming-events?user_id=${id}`);
		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.data;
			console.error("getUpcomingEvents", errObj);

		}
		console.error("getUpcomingEvents", error);
	}
}


export const upadteScheduleMeetingOptions = (data) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: SCHEDULE, schedulemeeting: data });
		return true; // return success to frontend
	} catch (error) {


	}
}


export const getMeetingUrlByTempId = (temp_id, user_id) => async (dispatch, getState, api) => {
	dispatch({ type: MEETING });
	try {
		const user = store('authUser');
		const response = await api.get(apiUrl + `get-meeting-url-by-temp?user_id=${user.id}&temp_id=${temp_id}`);

		if (!response) {
			dispatch({ type: MEETING_FAIL, error: 'No data received' });
			return false;
		}
		dispatch({ type: MESSAGING_SUCCESS, messaging: response.data });
		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			dispatch({ type: MEETING_FAIL, error: errObj });
		}
		console.error("meetingData", error);
	}
}

export const getUpcomingEventsForMeet = (id, search_key) => async (dispatch, getState, api) => {
	try {
		
		const response = await api.get(apiUrl + `get-upcoming-events-for-meet?user_id=${id}&search_key=${search_key}`);
		dispatch({ type: UPCOMINGEVENTS, upcomingevents: response.data });
		return response; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.data;
			console.error("getUpcomingEventsForMeet", errObj);

		}
		console.error("meetingData", error);
	}
}

export const updateHistoryUpcomingEvents = (upcoming, history) => async (dispatch, getState, api) => {
	dispatch({ type: UPCOMINGEVENTS, upcomingevents: upcoming });
	dispatch({ type: HISTORYEVENTS, historyevents: history });

}

export const getHistoryEventsForMeet = (id, search_key) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-history-events-for-meet?user_id=${id}&search_key=${search_key}`);
		dispatch({ type: HISTORYEVENTS, historyevents: response.data });
		return response.data; // return success to frontend
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.data;
			console.error("getHistoryEventsForMeet", errObj);

		}
		console.error("meetingData", error);
	}
}

export const startMeetingRecording = (params: any) => async (dispatch, getState, api) => {
	try {
		const meetingURL = encodeURIComponent(`${config.appUrl}/meeting/preview?title=${params.meetingTitle}&userType=bot&name=bot`);
		params.meetingUrl = meetingURL; 
		const response = await superagent.post(apiUrl + `start-meeting-recording`)
			.send(params)
			.set({ 'Content-Type': 'application/json' });
		if (response && response.body) {
			const result = response.body;
			console.error("startMeetingRecording:response", result);
			return result.data;
		}
	} catch (error) {
		console.error("startMeetingRecording:error", error);
	}
}

export const stopMeetingRecording = (params: any) => async (dispatch, getState, api) => {
	try { 
		const response = await superagent.post(apiUrl + `stop-meeting-recording`)
			.send(params)
			.set({ 'Content-Type': 'application/json' });
		if (response && response.body) {
			const result = response.body;
			console.error("stopMeetingRecording:response", result);
			return result.data;
		}
	} catch (error) {
		console.error("stopMeetingRecording:error", error);
	}
}

export const saveRecordingConsent = (params: any) => async (dispatch, getState, api) => {
	try { 
		const response = await superagent.post(apiUrl + `save-recording-consent`)
			.send(params)
			.set({ 'Content-Type': 'application/json' }); 
		if (response && response.body) {
			const result = response.body; 
			return result.data;
		}
	} catch (error) {
		const errObj = error.response.body;
		console.error("saveMeetingRecording:error", errObj);
	}
}

export const UpdateRecordingConsent = (params: any) => async (dispatch, getState, api) => {
	dispatch({ type: RECORDING, Consent: params });
}

export const updateParticipants = (id, participants) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-participants-data`, { data: { id, participants } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForParticipants", errObj);
		}
	}
}

export const createNewInvite = (params) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `create-new-invite`, { data: { params } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("createNewInvite", errObj);
		} else {
			console.error("createNewInvite", error);
		}
	}
}

export const updateInviteData = (id, slots) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-Data`, { data: { id, slots } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteData", errObj);
		}
	}
}

export const getInviteDataById = (id) => async (dispatch, getState, api) => {
	try {
		const response = await api.get(apiUrl + `get-invite-by-id?id=${id}`);
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("getInviteDataById", error);
	}
};

export const updateInviteForParticipants = (id, participants, isResheduled) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-participants-data`, { data: { id, participants, isResheduled } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForParticipants", errObj);
		}
	}
}

export const updateInviteForParticipantsDara = (id, participants, isResheduled) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-invite-participants`, { data: { id, participants, isResheduled } })
		if (!result) {
			return false;
		}
		return result.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateTemplateForParticipants", errObj);
		}
	}
}

export const sendParticipantsInviteMail = (id, location) => async (dispatch, getState, api) => {
	try { 
		const response = await api.post(apiUrl + `send-participant-invite-email`, { data: { id: id, location: location } });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("rejectDigicard", error);
	}
};

export const saveInviteDataRedux = (data) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: INVITE_DATA_REDUX, inviteredux: data });
		return true; // return success to frontend
	} catch (error) {
	}
}

export const updateExchangeDigicardState = (data) => async (dispatch, getState, api) => {
	try {
		dispatch({ type: DIGICARD_DATA_REDUX, digicardredux: data });
		return true; // return success to frontend
	} catch (error) {
		console.error("rejectDigicard", error);
	}
}
export const setMeetingSession = meetingSession => async (dispatch) => {
	dispatch({
		type: SET_MEETING_SESSION,
		payload: meetingSession
	})
}

export const setSessionUser = (user) => async (dispatch, getState, api) => {
	dispatch({
		type: MEET_USER,
		payload: user
	})

}

export const ResetMeeting = () => async (dispatch, getState, api) => {
	// console.log("ResetMeeting")
	dispatch({ type: MEETING_RESET })
}

export const startConversation = (attendee) => async (dispatch, getState, api) => {
	dispatch({
		type: START_CONVERSATION,
		conversationData: attendee
	})
}

export const setDigiCardStatus = (userId, status) => async (dispatch, getState, api) => {
	let { digiCardStatus } = getState().meeting;
	if (digiCardStatus) {
		digiCardStatus[userId] = status;
	} else {
		digiCardStatus = {
			[userId]: status
		};
	}

	dispatch({
		type: SET_DIGICARD_STATUS,
		payload: digiCardStatus
	});
}

export const updateScheduleMeeting = (meeting) => async (dispatch, getState, api) => {
	try {
		const response = await superagent.post(apiUrl + `update-schedule-meeting`)
			.send(meeting)
			.set({ 'Content-Type': 'application/json' });

		var result = response.body;

		if (!result) {
			return false;
		}
		return result.data; // return success to frontend
	} catch (error) {
		console.error("createMeeting", error);
	}
}

export const removeContact = (params) => async (dispatch, getState, api) => {
	try {
		const response = await api.post(apiUrl + `delete-digicard`, { data: params });
		return response.data;
	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			return errObj; // return success to frontend
		}
		console.error("removeContact..", error);
	}
};

export const deleteMeeting = (id) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-meeting-status`, { data: { id, status: 'deleted' } })
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteStatus", errObj);
		}
	}
}

export const setMeetingEventStatus = (params) => async (dispatch, getState, api) => {
	try {
		const result = await api.post(apiUrl + `update-meeting-status`, { data: params })
		if (!result) {
			return false;
		}
		return result.data;

	} catch (error) {
		if (error.response !== undefined) {
			const errObj = error.response.body;
			console.error("updateInviteStatus", errObj);
		}
	}
}
//#endregion actions

export const getAllUserMeeting = (limit = 50, offset = 0) => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: SET_USER_MEETINGS
		});
		const userID = getState().auth.user.id;
		const res = await api.post('meeting/get-user-meetings', {
			data: {
				userID,
				// offset,
				// limit
			}
		});
		// console.log('res', res);
		dispatch({
			type: SET_USER_MEETINGS_SUCCESS,
			payload: res.data
		});
		return res.data;
	} catch {
		toastr.error('Error', 'Something went wrong!');
	}
}

export const setMeetingNote = (note) => async (dispatch, getState, api) => {
	dispatch({
		type: SET_MEETING_NOTE,
		payload: note
	});
}

export const getRecordingsByMeetingId = (meetingId) => async (dispatch, getState, api) => {
	try {
		// dispatch({
		// 	type: SET_USER_MEETINGS
		// });
		const userID = getState().auth.user.id;
		const res = await api.post('file/get-recordings-by-meeting-title', {
			data: {
				user_id: userID,
				meeting_title: meetingId
				// offset,
				// limit
			}
		}); 
		return res?.data;
		// dispatch({
		// 	type: SET_USER_MEETINGS_SUCCESS,
		// 	payload: res.data
		// });
	} catch {
		toastr.error('Error', 'Something went wrong!');
	}
}
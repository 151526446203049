import { push } from 'react-router-redux';
import store from 'store2';
// import { push } from 'react-router-redux';
import superagent from 'superagent';
import config from '../../clientConfig';

const modulePath = "auth/";
const apiUrl = config.apiUrl + modulePath;

const LOAD = `${modulePath}LOAD`;
const LOAD_SUCCESS = `${modulePath}LOAD_SUCCESS`;
const LOAD_FAIL = `${modulePath}LOAD_FAIL`;

const SIGNUP_STEP_ONE = `${modulePath}SIGNUP_STEP_ONE`;
const SIGNUP = `${modulePath}SIGNUP`;
const SIGNUP_SUCCESS = `${modulePath}SIGNUP_SUCCESS`;
const SIGNUP_FAIL = `${modulePath}SIGNUP_FAIL`;
const SIGNUP_FLUSH = `${modulePath}SIGNUP_FLUSH`;

const LOGIN = `${modulePath}LOGIN`;
const LOGIN_SUCCESS = `${modulePath}LOGIN_SUCCESS`;
const LOGIN_FAIL = `${modulePath}LOGIN_FAIL`;

const RESEND_CONFIRMATION = `${modulePath}CONFIRMATION`;
const RESEND_CONFIRMATION_SUCCESS = `${modulePath}CONFIRMATION_SUCCESS`;
const RESEND_CONFIRMATION_FAIL = `${modulePath}CONFIRMATION_FAIL`;

const CONFIRMATION = `${modulePath}CONFIRM_CODE`;
const CONFIRMATION_SUCCESS = `${modulePath}CONFIRM_CODE_SUCCESS`;
const CONFIRMATION_FAIL = `${modulePath}CONFIRM_CODE_FAIL`;

const FORGOTPASSWORD = `${modulePath}FORGOTPASSOWRD`;
const FORGOTPASSWORD_SUCCESS = `${modulePath}FORGOTPASSOWRD_SUCCESS`;
const FORGOTPASSWORD_FAIL = `${modulePath}FORGOTPASSOWRD_FAIL`;

// const RESETPASSWORD = 'auth/RESETPASSWORD';
// const RESETPASSWORD_SUCCESS = 'auth/RESETPASSWORD_SUCCESS';
// const RESETPASSWORD_FAIL = 'auth/RESETPASSWORD_FAIL';

// const CHANGEPASSWORD = 'auth/CHANGEPASSWORD';
// const CHANGEPASSWORD_SUCCESS = 'auth/CHANGEPASSWORD_SUCESS';
// const CHANGEPASSWORD_FAIL = 'auth/CHANGEPASSWORD_FAIL';

// const SETPASSWORD = 'auth/SETPASSWORD';
// const SETPASSWORD_SUCCESS = 'auth/SETPASSWORD_SUCCESS';
// const SETPASSWORD_FAIL = 'auth/SETPASSWORD_FAIL';

const LOGOUT = `${modulePath}LOGOUT`;

const FLUSH = `${modulePath}FLUSH`;

const initialState = {
  loadBusy: false,
  loadError: false,
  user: null,
  loginBusy: false,
  loginError: null,
  signupBusy: false,
  signupError: null,
  message: null,
  forgotPasswordBusy: false,
  forgotPasswordMessage: null,
  resendConfirmationBusy: false,
  resendConfirmationMessage: null,
  confirmationCodeBusy: false,
  confirmationCodeMessage: null,
  confimationData: null,
  resetPasswordBusy: false,
  resetPasswordMessage: null,
  resetPasswordRedirect: false,
  userDataBusy: false,
  userDataBusyError: false,
  userData: null,
  changePasswordBusy: false,
  changePasswordError: null,
  changePassword: null
};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case LOAD:
      return { ...state, loadBusy: true, loadError: null };

    case LOAD_SUCCESS:
      return { ...state, loadBusy: false, user: action.user };

    case LOAD_FAIL:
      return { ...state, loadBusy: false, user: null, loadError: action.error };

    case SIGNUP_STEP_ONE:
      return { ...state, signupBusy: false, signupError: action.error, user: action.user, terms: action.terms };

    case SIGNUP:
      return { ...state, signupBusy: true, signupError: null };

    case SIGNUP_SUCCESS:
      return { ...state, signupBusy: false, user: action.user };

    case SIGNUP_FAIL:
      return { ...state, signupBusy: false, signupError: action.error, user: null };
    
    case SIGNUP_FLUSH:
        return { ...state, signupBusy: false, signupError: null, user: null };

    case LOGIN:
      return { ...state, loginBusy: true, loginError: null };

    case LOGIN_SUCCESS:
      return { ...state, loginBusy: false, user: action.user };

    case LOGIN_FAIL:
      return { ...state, loginBusy: false, loginError: action.error, user: null };

    case FORGOTPASSWORD:
      return { ...state, forgotPasswordBusy: true };

    case FORGOTPASSWORD_SUCCESS:
      return { ...state, forgotPasswordBusy: false, forgotPasswordMessage: action.message, email: action.email };

    case FORGOTPASSWORD_FAIL:
      return { ...state, forgotPasswordBusy: false, forgotPasswordMessage: action.error };

    case RESEND_CONFIRMATION:
      return { ...state, resendConfirmationBusy: true };

    case RESEND_CONFIRMATION_SUCCESS:
      return { ...state, resendConfirmationBusy: false, resendConfirmationMessage: action.message, email: action.email };

    case RESEND_CONFIRMATION_FAIL:
      return { ...state, resendConfirmationBusy: false, resendConfirmationMessage: action.error };

    case CONFIRMATION:
      return { ...state, confirmationCodeBusy: true };

    case CONFIRMATION_SUCCESS:
      return { ...state, confirmationCodeBusy: false, confirmationCodeMessage: action.message, confimationData: action.data };

    case CONFIRMATION_FAIL:
      return { ...state, confirmationCodeBusy: false, confirmationCodeMessage: action.error };

    case LOGOUT:
      return { ...state, user: null }

    case FLUSH: {
      return initialState;
    }
    default:
      return state;
  }
}


export const testLogin = () => async (dispatch, getState, api) => {
  try {
    const h = await api.get('templates/find-all');

    console.log('result', h);
  } catch (err) {
    console.log();
  }
}

export const userEmailInStore = (user) => async (dispatch, getState, api) => {
  try {
    //debugger
    dispatch({ type: SIGNUP });
    const result = await superagent.post(apiUrl + `pre-signup`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });
    // receive success or error response here
    var response = result.body;
    console.log(response);
    if (response.data && response.data.status === true) {
      const error = {
        type: "error",
        message: "User with this email already exists."
      }
      dispatch({ type: SIGNUP_FAIL, error });
    } else if (response.data && response.data.status === false) {
      const error = {
        type: "success",
        message: "Welcome to Strike. Please proceed with the Sign up process."
      }
      dispatch({ type: SIGNUP_STEP_ONE, error, user: response.data.email, terms: response.data.terms });
      return response; // return success to frontend
    } else {
      console.log("error occur" + response.toString());
      const error = {
        type: "error",
        message: "Something went wrong."
      }
      dispatch({ type: SIGNUP_FAIL, error });
    }
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("userEmailInStore", errObj);
      dispatch({ type: SIGNUP_FAIL, error: errObj });
      return;
    }
    dispatch({ type: SIGNUP_FAIL, error: error });
    console.log("userEmailInStore", error);
  }
}

export const signUpStepOne = (user) => async (dispatch, getState, api) => {
  try {
    //debugger
    dispatch({ type: SIGNUP });
    const result = await superagent.post(apiUrl + `signup-step-one`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });
    // receive success or error response here
    var response = result.body;
    if (response.data && response.data.status === true) {
      const error = {
        type: "error",
        message: "User with this email already exists."
      }
      dispatch({ type: SIGNUP_FAIL, error });
    } else if (response.data && response.data.status === false) {
      const error = {
        type: "success",
        message: "Please proceed with the Sign up process."
      }
      dispatch({ type: SIGNUP_STEP_ONE, error, user: response.data.user, terms: response.data.terms });
      return response; // return success to frontend
    } else {
      console.log("error occur" + response.toString());
      const error = {
        type: "error",
        message: "Something went wrong."
      }
      dispatch({ type: SIGNUP_FAIL, error });
    }
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("signUpStepOne", errObj);
      dispatch({ type: SIGNUP_FAIL, error: errObj });
      return;
    }
    dispatch({ type: SIGNUP_FAIL, error: error });
    console.log("signUpStepOne", error);
  }
}

// signUp api call from reducer
export const signUp = (user) => async (dispatch, getState, api) => {
  dispatch({ type: SIGNUP });
  try {
    //debugger
    const result = await superagent.post(apiUrl + `signup`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });
    var response = result.body;
    dispatch({ type: SIGNUP_SUCCESS, user: response.data.user });
    return response; // return success to frontend
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("signUp", errObj);
      dispatch({ type: SIGNUP_FAIL, error: errObj });
      return false;
    }
    dispatch({ type: SIGNUP_FAIL, error: error });
    console.log("signUp", error);
  }
};

export const login = (user) => async (dispatch, getState, api) => {
  dispatch({ type: LOGIN });

  try {
    const response = await superagent.post(apiUrl + `login`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });
    var result = response.body;

    if (!result || !result.data.authToken) {
      dispatch({ type: LOGIN_FAIL, error: 'Missing data from api' });
      return;
    }

    // set authToken to local storage
    store('authToken', `Bearer ${result.data.authToken}`);
    store('authUser', result.data.user);

    dispatch({ type: LOGIN_SUCCESS, user: result.data.user });
    return result.data;
  } catch (error) {
    if (error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: LOGIN_FAIL, error: errObj });
      return false; // return success to frontend
    }
    console.log("loginerror", error);
    dispatch({ type: LOGIN_FAIL, error: error });
  }
};

export const forgotPassword = (user) => async (dispatch, getState, api) => {
  dispatch({ type: FORGOTPASSWORD });

  try {
    const response = await superagent.post(apiUrl + `forgot-password`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });

    const result = response.body;

    if (!result || !result.status) {
      dispatch({ type: FORGOTPASSWORD_FAIL, error: 'No data received' });
      return;
    }

    dispatch({ type: FORGOTPASSWORD_SUCCESS, message: result.data, email: user.Email });
    return true;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("forgotPassword", errObj);
      dispatch({ type: FORGOTPASSWORD_FAIL, error: errObj });
      return;
    }
    dispatch({ type: FORGOTPASSWORD_FAIL, error: error });
    console.log("forgotPassword", error);
  }
}

export const forgotPasswordConfirm = (user) => async (dispatch, getState, api) => {
  dispatch({ type: FORGOTPASSWORD });

  try {
    const response = await superagent.post(apiUrl + `forgot-password-confirm`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });

    var result = response.body;

    if (!result || !result.status) {
      dispatch({ type: FORGOTPASSWORD_FAIL, error: 'No data received' });
    }

    dispatch({ type: FORGOTPASSWORD_SUCCESS, message: result.data, email: user.Email });
    return true;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("forgotPasswordConfirm", errObj);
      dispatch({ type: FORGOTPASSWORD_FAIL, error: errObj });
      return errObj;
    }
    dispatch({ type: FORGOTPASSWORD_FAIL, error: error });
    console.log("forgotPasswordConfirm", error);
  }
}

export const checkIfUserExists = (user) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `check-email`)
      .send(user)
      .set({ 'Content-Type': 'application/json' });
    let result = response.body;
    return result;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("checkIfUserExists", errObj);
    }
    console.log("checkIfUserExists", error);
  }
}

export const updateTimezone = (timezone) => async (dispatch, getState, api) => { 
  try {
    dispatch({ type: LOAD });
    let user = getState().auth.user;
    user.timezone=timezone;
    dispatch({ type: LOAD_SUCCESS, user });
  } catch (error) {
    dispatch({ type: LOAD_FAIL, error });
  }
};



export const load = () => async (dispatch, getState, api) => {
  dispatch({ type: LOAD });
  const user = getState().auth.user;
  if (user) {
    dispatch({ type: LOAD_SUCCESS, user });
    return user;
  }
  try {
    const data = store('authUser');
    //console.log('check login', data);
    if (!data) {
      return user;
    }
    dispatch({ type: LOAD_SUCCESS, user: data });
  } catch (error) {
    dispatch({ type: LOAD_FAIL, error });
  }
};

export const logout = () => (dispatch, getState, api) => {
  store.remove('authToken');
  store.remove('authUser');
  dispatch({ type: LOGOUT });
  dispatch({ type: FLUSH });
  dispatch(push('/login'));
};

export const flush = () => (dispatch, getState, api) => {
  store.remove('authToken');
  store.remove('authUser');
  dispatch({ type: SIGNUP_FLUSH });
  dispatch({ type: LOGOUT });
  dispatch({ type: FLUSH });
};

export const resendConfirmationCode = (params) => async (dispatch, getState, api) => {
  dispatch({ type: RESEND_CONFIRMATION });

  try {
    const response = await superagent.post(apiUrl + `resend-verification`)
      .send(params)
      .set({ 'Content-Type': 'application/json' });

    const result = response.body;

    dispatch({ type: RESEND_CONFIRMATION_SUCCESS, message: result.data, email: params.email });
    return true;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("resendConfirmationCode", errObj);
      dispatch({ type: RESEND_CONFIRMATION_FAIL, error: errObj });
      return;
    }
    dispatch({ type: RESEND_CONFIRMATION_FAIL, error: error });
    console.log("resendConfirmationCode", error);
  }
}

export const userConfirmCode = (params) => async (dispatch, getState, api) => {
  dispatch({ type: CONFIRMATION });

  try {
    const response = await superagent.post(apiUrl + `confirm-user-code`)
      .send(params)
      .set({ 'Content-Type': 'application/json' });

    const result = response.body;

    dispatch({ type: CONFIRMATION_SUCCESS, message: 'User confirm success', data: result.data });
    return true;
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.log("userConfirmCode", errObj);
      dispatch({ type: CONFIRMATION_FAIL, error: errObj });
      return errObj;
    }
    dispatch({ type: CONFIRMATION_FAIL, error: error });
    console.log("userConfirmCode", error);
  }
}

export const updateUserVerifyEmailStatus = (params) => async (dispatch, getState, api) => {
  try {
    const response = await superagent.post(apiUrl + `update-user-verify-status`)
    .send(params)
    .set({ 'Content-Type': 'application/json' });
    
    const result = response.body;
    return result;    
  } catch (error) {
    if(error.response) {
      const errObj = error.response.body;
      console.error("updateUserVerifyEmailStatus", errObj);
      return errObj;
    }
    console.error("updateUserVerifyEmailStatus", error);
  }
}

export const updateAuthUserRedux = (params) => async (dispatch, getState, api) => {
  dispatch({type: LOGIN_SUCCESS, user: params.user})
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import createStore from './redux/configureStore';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import Amplify from 'aws-amplify';
// import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { sentryDSN, ChargebeeConfig } from "./config/authConfig"
// import HttpsRedirect from 'react-https-redirect';

declare global {
  interface Window {
    Chargebee: any;
  }
}

let Chargebee = window.Chargebee;

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if(Chargebee) {
  Chargebee.init({
    site: ChargebeeConfig.site,
    publishableKey: ChargebeeConfig.publishableKey
  })
}

Amplify.configure(awsconfig);
// Auth.currentCredentials()
//   .then(d => console.log('data: ', d))
//   .catch(e => console.log('error: ', e))

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});
const store = createStore(history, {});

ReactDOM.render(
  <Provider store={store} >
    <App />
    <ReduxToastr
      position="bottom-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
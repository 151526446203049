const config: any = {
  local: {
    appUrl: 'http://localhost:3050',
    apiUrl: 'http://localhost:8050/v1/api/',
    serverApiUrl: 'http://localhost:5000/',
    websocket: "http://localhost:8050",
    s3_public_file_url: `https://lambda-filequeue-dev.s3.amazonaws.com/`,
  },
  
  development: {
    appUrl: 'https://dev.strikeapp.co',
    apiUrl: 'https://dev-api.strikeapp.co/v1/api/',
    serverApiUrl: 'https://dev-meetings.strikeapp.co/',
    websocket: "https://dev-api.strikeapp.co",
    s3_public_file_url: `https://lambda-filequeue-dev.s3.amazonaws.com/`,
  },

  testing: {
    appUrl: 'https://test.strikeapp.co',
    apiUrl: 'https://test-api.strikeapp.co/v1/api/',
    serverApiUrl: 'https://test-meetings.strikeapp.co/',
    websocket: "https://test-api.strikeapp.co",
    s3_public_file_url: `https://lambda-filequeue-test.s3.amazonaws.com/`,
  },
  staging: {
    appUrl: 'https://uat.strikeapp.co',
    apiUrl: 'https://uat-api.strikeapp.co/v1/api/',
    serverApiUrl: 'https://uat-meetings.strikeapp.co/',
    websocket: "https://uat-api.strikeapp.co",
    s3_public_file_url: `https://lambda-filequeue-staging.s3.amazonaws.com/`,
  },
  prod: {
    appUrl: 'https://www.strikeapp.co',
    apiUrl: 'https://api.strikeapp.co/v1/api/',
    serverApiUrl: 'https://meetings.strikeapp.co/',
    websocket: "https://api.strikeapp.co",
    s3_public_file_url: `https://lambda-filequeue-prod.s3.amazonaws.com/`,
  },
};

export default config[process.env.REACT_APP_ENV || 'local'];

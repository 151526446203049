import superagent from 'superagent';
import config from '../../clientConfig';

const apiUrl = config.apiUrl+'notification/';

const NOTIFICATION= 'notification/NOTIFICATION';
const NOTIFICATION_GET= 'notification/GetUnReadNotification';
const NOTIFICATION_FAIL = 'notification/NOTIFICATION_FAIL';
const initialState = {
  NotificationBusy: false,
  NotificationError: false,
  Notifications: [],
  NotificationCount: 0, 
};

export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case NOTIFICATION:
      return { ...state, NotificationBusy: true, NotificationError: null, NotificationCount: 0, Notifications: [] };
   case NOTIFICATION_GET:
        return { ...state, NotificationBusy: true, Notifications: action.notification, NotificationCount: action.count, NotificationError: null };
  case NOTIFICATION_FAIL:
      return { ...state, NotificationBusy: false, Notifications: null, NotificationError: action.error };
    default:
      return state;
  }
}



export const GetNotifications = (userId) => async (dispatch, getState, api) => {
  dispatch({ type: NOTIFICATION });
  try {
    const result = await superagent.get(apiUrl + `get-unread-notifications?userId=${userId}&page=1&perPage=10`)
    .send()
    .set({'Content-Type': 'application/json'});
    const response = result.body;
    dispatch({ type: NOTIFICATION_GET, notification: response.data.notifications, count: response.data.totalCount });

    return response.data; // return success to frontend
  } catch (error) {
    if(error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: NOTIFICATION_FAIL, error: errObj });
    }
    console.error("GetNotifications",error);
  }
};
export const readNotification = (id) => async (dispatch, getState, api) => {
  dispatch({ type: NOTIFICATION });
  try {
    await superagent.get(apiUrl + `read-notifications?id=`+id)
    .send()
    .set({'Content-Type': 'application/json'});
  } catch (error) {
    if(error.response !== undefined) {
      const errObj = error.response.body;
      dispatch({ type: NOTIFICATION_FAIL, error: errObj });
    }
    console.error("readNotification",error); 
  }
};





import Loadable from 'react-loadable';
import Loading from './components/Loading';
import home from './containers/Home/home';
import about from './containers/Home/about';

const routes = [
  {
    path: '/',
    exact: true,
    component: home
  },
  {
    path: '/about-us',
    exact: true,
    component: about
  },
  {
    path: '/template/:username',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/GlobalLinkWindow'), loading: Loading })
  },
  {
    path: '/error/:code',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Error/ErrorPage'), loading: Loading })
  },
  {
    path: '/participants',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/AddParticipantsByParticipant'), loading: Loading })
  },
  {
    path: '/summary',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/InviteSummaryAtParticipant'), loading: Loading })
  },
  {
    path: '/invite/:username/:template/:user',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/TemplateInvitationWindow'), loading: Loading })
  },
  {
    path: '/invite/:username/:template',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/TemplateInvitationWindow'), loading: Loading })
  },
  {
    path: '/template/:username/:template',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/GlobalLinkWindow'), loading: Loading })
  },
  {
    path: '/register',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Register/PreRegister'), loading: Loading })
  },
  {
    path: '/pre-register',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Register/PreRegister'), loading: Loading })
  },
  {
    path: '/register-consent',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Register/RegisterConsent'), loading: Loading })
  },
  {
    path: '/verify-email',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Register/VerifyEmail'), loading: Loading })
  },
  {
    path: '/login',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login'), loading: Loading })
  },
  {
    path: '/forgot-password',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login/ForgotPassword'), loading: Loading })
  },
  {
    path: '/forgot-password-confirm',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login/ForgotPasswordConfirm'), loading: Loading })
  },
  {
    path: '/user/dashboard',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/invite',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/schedule-meeting-participants',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/schedule-meeting-options',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/schedule-save-meeting',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/sync',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/profile',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/plans',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/billing',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/contacts',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/participants',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/user/invite-summary',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/InviteSummary'), loading: Loading })
  },
  {
    path: '/user/sync-proposals',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/SyncProposals'), loading: Loading })
  },
  {
    path: '/meeting/preview',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Meeting/MeetingPreview'), loading: Loading })
  },
  {
    path: '/meeting/dashboard',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Meeting/MeetingDashboard'), loading: Loading })
  },
  {
    path: '/meeting/verify',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Meeting/MeetingRoom'), loading: Loading })
  },
  {
    path: '/meeting/:id',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Meeting/MeetingRoom'), loading: Loading })
  },
  {
    path: '/invite/connect-calendar',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/ConnectCalendar'), loading: Loading })
  },
  {
    path: '/invite/connect-settings',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/ConnectSettings'), loading: Loading })
  },
  {
    path: '/invite/calendar-availability',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/CalendarAvailability'), loading: Loading })
  },
  {
    path: '/invite/success',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/CalendarSetupComplete'), loading: Loading })
  },
  {
    path: '/user/profile/success',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/DigicardSetupComplete'), loading: Loading })
  },
  {
    path: '/invite/personal-settings',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/PersonalSettings'), loading: Loading })
  },
  {
    path: '/invite/calendar-settings',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Invite/CalendarSetup'), loading: Loading })
  },
  {
    path: '/user/files',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/files/transcriptions/:meetingTitle',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/meet',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/meeting-history',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/user/meeting-history/:meetingId',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/settings/calendar',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading }),
  },
  {
    path: '/privacypolicy',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Settings/PrivacyPolicy'), loading: Loading }),
  },
  {
    path: '/cancelled-event',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Settings/MeetingCancelledPopup'), loading: Loading }),
  },
  {
    path: '/scheduled-event',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Settings/MeetingAlreadyScheduledPopup'), loading: Loading }),
  },
  {
    path: '/invalid-invite',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Settings/MeetingInviteInvalidPopup'), loading: Loading }),
  },
  {
    path: '/invitation-already-submitted',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Settings/MeetingInvitePopup'), loading: Loading }),
  },
  {
    path: '/email-verified/:status/:user',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login/EmailVerify'), loading: Loading })
  },
  {
    path: '/confirm-user-code',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Login/UserConfirmCode'), loading: Loading })
  },
  {
    path: '/invite/work-diary',
    exact: true,
    component: Loadable({ loader: () => import('./containers/User/UserDashboard'), loading: Loading })
  },
  {
    path: '/join-waitlist',
    exact: true,
    component: Loadable({ loader: () => import('./containers/Waitlist/JoinWaitlistContainer'), loading: Loading })
  },
];

export default routes;
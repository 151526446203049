import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import 'semantic-ui-css/semantic.min.css';
import routes from '../../routes';
import '../../../src/App.scss';
import "../../assets/style/index.scss";

const App = ({ children }: any) => (
  <Router>
    <Switch>
      {renderRoutes(routes)}
    </Switch>
  </Router>
);

App.propTypes = {
  children: PropTypes.object,
};

App.defaultProps = {
  children: null,
  data: null
};

export default App;